import backendConnector from '@/services/backendConnector'
import i18n from '@/plugins/vue-i18n'
import { specAssists, typeAssists, detailsAssists } from './assist'
import store from '@/store/store.js'

/* presta statuses = ['todo', 'done', 'reported', 'notDone', 'cancelled'] */
let prestaStatusesTechnicalNames = {}
/* presta reasons = ['customerCancellation', 'wheelchairOtherStation', 'postponementDeparture', 'substitutionOtherStation', 'trainRemovedOtherStation',
  'entryError', 'entryErrorOtherStation', 'excessBaggage', 'unavailableEquipment', 'wheelchair', 'noShowClient', 'customerDelay', 'noPersonnelAvailable',
  'rejectionOtherStation', 'postponementArrival', 'trainLate', 'substitution', 'trainRemoved', 'other'] */
let prestaReasonsTechnicalNames = {}
let prestaTypesByTechnicalNames = { garanteed: 'Garantie', notGaranteed: 'Non garantie' }
let prestaStatuses = { DONE: 'Réalisée', NOT_DONE: 'Non réalisée', TO_DO: 'À réaliser', REPORTED: 'Reportée', CANCELLED: 'Annulée' }
let prestaStatesByTechnicalNames = { accepted: 'acceptée', refused: 'refusée', reported: 'reportée' }
let civilityByTechnicalNames = { mister: 'Monsieur', madam: 'Madame' }
let prestaMentionsByTechnicalNames = { departure: { sigle: 'D' },
  arrival: { sigle: 'A' },
  transitArrival: { sigle: 'AC' },
  transitDeparture: { sigle: 'DC' },
  none: { sigle: 'N' }
}
const onBoardMentions = [ { "sigle": "D", "label": "Départ" }, { "sigle": "A", "label": "Arrivée" } ]
let onboardingDocStatusSigle = ['chief', 'conductor', 'easNo', 'chiefNo', 'otherNo']
let onboardingDocStatusByTechnicalNames = {}
let transportModesByTechnicalNames = { car: 'Car', train: 'Train', taxi: 'Taxi' }
let agencies = []
let regions = []
let featuresFlipped = []
let alertTypes = ['works', 'strike', 'closedStation', 'unavailableEquipment', 'other']
let naturesAssistance = ['Gare', 'Bord', 'Route']
let responsablesAssistance = ['garesAndConnexions', 'Transporteur']

let libraryThemes = []

let refList = {}

const customerInformationList = ['notifiedClientNoService', 'notifiedClientNoComitment', 'notifiedClientStationInaccessible', 'customerRenounces', 'ownMeans', 'arrivalAssistance', 'beforeOrAfter', 'noRequest']

let initPrestaStatuses = function () {
  prestaStatusesTechnicalNames = refList['prestation']['statuses']
}
let initPrestaReasons = function () {
  prestaReasonsTechnicalNames = refList['prestation']['reasons']
}

let initAgencies = function () {
  agencies = refList['agencies']
}

let initRegions = function () {
  regions = refList['regions']
}

let initFeaturesFlipped = function () {
  featuresFlipped = refList['featuresFlipped']
}

let initMentions = function (locale) {
  const keys = Object.keys(prestaMentionsByTechnicalNames)
  keys.map(k => {
    prestaMentionsByTechnicalNames[k]['label'] = i18n.t('prestation.mention.' + k, locale || i18n.locale)
  })
}

let initAssist = function (locale) {
  const keysType = Object.keys(typeAssists)
  keysType.map(k => {
    typeAssists[k].specs = typeAssists[k].specs.split('|').map(s => s.trim()).filter(s => s)
      .map(s => specAssists[s])
    typeAssists[k].details = typeAssists[k].details.split('|').map(s => s.trim()).filter(s => s)
      .map(s => detailsAssists[s])
  })

  const keysSpecs = Object.keys(specAssists)
  keysSpecs.map(k => {
    specAssists[k].details = specAssists[k].details.split('|').map(s => s.trim()).filter(s => s)
      .map(s => detailsAssists[s])
  })

  translateAssist(locale)
}

let translateAssist = function (locale) {
  [typeAssists, specAssists, detailsAssists].forEach(a => {
    const keys = Object.keys(a)
    keys.map(k => {
      a[k]['complete'] = i18n.t('prestation.assist.' + k, locale || i18n.locale)
      a[k]['allLetters'] = a[k]['acronym'] + ' - ' + i18n.t('prestation.assist.' + k, locale || i18n.locale)
    })
  })
}

let initOnboardingDocStatus = function (locale) {
  onboardingDocStatusSigle.map(k => {
    onboardingDocStatusByTechnicalNames[k] = i18n.t('prestation.onboarding.' + k, locale || i18n.locale)
  })
}

let translateList = function (list, tradKey) {
  let translatedList = {}
  list.forEach(function (code) {
    translatedList[code] = i18n.t(tradKey + '.' + code)
  })
  return translatedList
}

// locale change
store.watch(
  (state, getters) => getters.userLocale,
  (newValue, oldValue) => {
    initMentions()
    translateAssist()
    initOnboardingDocStatus()
  }
)

export default {
  initialize: function () {
    return new Promise(function (resolve, reject) {
      backendConnector.getAllRefs()
        .then(results => {
          refList = results

          // Locales messages init
          for (let loc in refList.locales) {
            i18n.setLocaleMessage(loc, refList.locales[loc])
          }
          delete refList.locales

          // other refs init
          store.commit('setCharterDate', refList.charterDate ? new Date(refList.charterDate) : null)
          initPrestaStatuses()
          initPrestaReasons()
          initAgencies()
          initRegions()
          initMentions()
          initAssist()
          initOnboardingDocStatus()
          initFeaturesFlipped()
          libraryThemes = refList.libraryThemes
          resolve(results)
        })
        .catch(err => {
          reject(new Error(i18n.t('common.ref_fetch_error')))
          console.log('error fetching ref data: ' + err)
        })
    })
  },

  getTypeAssist: () => Object.values(typeAssists),

  getSpecAssist: () => Object.values(specAssists),

  getTransportersModels: () => {
    return store.getters.userRestrictedRefs.transporters || []
  },

  getTransportersModelsForUpsert: () => {
    return store.getters.userRestrictedRefs.transporters.filter(t => t.showInListForUpsert) || []
  },

  getTransportersModelsForFilters: () => {
    return store.getters.userRestrictedRefs.transporters.filter(t => t.showInListForFilters) || []
  },

  getTransportersByCode: (code) => {
    if (!code) return null
    let transporterList = store.getters.userRestrictedRefs.transporters
    for (let transporter of transporterList) {
      if (transporter.code === code) {
        return transporter.name
      }
    }
  },

  getTransporterModelByCode: (code) => {
    if (!code) return null
    return store.getters.userRestrictedRefs.transporters.find((transporter) => transporter.code === code)
  },

  // TO DO : Update Transporter list with all new transporters.

  getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous: () => {
    return ['SNCF', 'EUROSTAR', 'TGV_INOUI', 'TGV_OUIGO', 'TGV_LYRIA', 'OUIGO_TC', 'TRANSILIEN', 'THALYS', 'THELLO', 'TGV', 'DEUTSCHE_BAHN_SNCF', 'RENFE_SNCF', 'TRENITALIA', 'TGV_INTERCITES', 'RENFE']
  },

  getTransportersCodesWithMandatoryCarAndPlaceOnGarenteed: () => {
    return ['SNCF', 'EUROSTAR', 'TGV_INOUI', 'TGV_LYRIA', 'OUIGO_TC', 'TRANSILIEN', 'THALYS', 'THELLO', 'TGV', 'DEUTSCHE_BAHN_SNCF', 'RENFE_SNCF', 'TRENITALIA', 'TGV_INTERCITES', 'RENFE']
  },

  getServicesModelsForUpsert: (all = false) => {
    const serviceList = all ? refList['services'] : store.getters.userRestrictedRefs.services
    return serviceList.filter(t => t.showInListForUpsert) || []
  },

  getServicesModelsForFilters: (all = false) => {
    const serviceList = all ? refList['services'] : store.getters.userRestrictedRefs.services
    return serviceList.filter(t => t.showInListForFilters) || []
  },

  getPrestaStatusesTechnicalNames: () => {
    return [...prestaStatusesTechnicalNames]
  },
  getPrestaReasonsTechnicalNames: () => {
    return [...prestaReasonsTechnicalNames]
  },

  getPrestaTypes: () => {
    return Object.values(prestaTypesByTechnicalNames)
  },
  getPrestaStatuses: () => {
    return prestaStatuses
  },
  getPrestaTypesByTechnicalName: () => {
    return prestaTypesByTechnicalNames
  },
  getPrestaTypeTechnicalNameByLabel: (lbl) => {
    for (const key in prestaTypesByTechnicalNames) {
      if (prestaTypesByTechnicalNames[key] === lbl) {
        return key
      }
    }
  },

  getPrestaStates: () => {
    return Object.values(prestaStatesByTechnicalNames)
  },
  getPrestaStatesByTechnicalName: () => {
    return prestaStatesByTechnicalNames
  },
  getPrestaStatesTechnicalNameByLabel: (lbl) => {
    for (const key in prestaStatesByTechnicalNames) {
      if (lbl === 'Refusée') {
        lbl = 'refusée'
      }
      if (prestaStatesByTechnicalNames[key] === lbl) {
        return key
      }
    }
  },

  getParcoursStatuses: () => {
    return ['Réalisé', 'Non Réalisé', 'Partiellement réalisée']
  },

  getInitMentions: (locale) => {
    initMentions(locale)
  },
  getMentions: () => {
    return Object.values(prestaMentionsByTechnicalNames)
  },
  getMentionsByTechnicalName: () => {
    return prestaMentionsByTechnicalNames
  },
  getMentionsBySigle: (sigle) => {
    const mentions = Object.values(prestaMentionsByTechnicalNames)
    const found = mentions.find(m => m.sigle === sigle)
    return found.label
  },
  getOnBoardMentions() {
    return onBoardMentions
  },

  getTransportModes: () => {
    return Object.values(transportModesByTechnicalNames)
  },
  getTransportModesByTechnicalName: () => {
    return transportModesByTechnicalNames
  },
  getTransportModeTechnicalNameByLabel: (lbl) => {
    for (const key in transportModesByTechnicalNames) {
      if (transportModesByTechnicalNames[key] === lbl) {
        return key
      }
    }
  },

  getCivilities: () => {
    return Object.values(civilityByTechnicalNames)
  },
  getCivilitiesByTechnicalName: () => {
    return civilityByTechnicalNames
  },
  getCivilityTechnicalNameByLabel: (lbl) => {
    for (const key in civilityByTechnicalNames) {
      if (civilityByTechnicalNames[key] === lbl) {
        return key
      }
    }
  },

  getInitOnboardingDocStatus: (locale) => {
    initOnboardingDocStatus(locale)
  },
  getOnboardingDocStatus: () => {
    return Object.values(onboardingDocStatusByTechnicalNames)
  },
  getOnboardingDocStatusByTechnicalName: () => {
    return onboardingDocStatusByTechnicalNames
  },

  // Referentiels utilisateurs
  getUserStatuses: (withTrad = true) => {
    let result = refList['user']['statuses']
    return withTrad ? translateList(result, 'user.statusList') : result
  },
  getUserRoles: (withTrad = true) => {
    let result = refList['user']['roles']
    return withTrad ? translateList(result, 'user.roleList') : result
  },
  getUserRestrictedRoles: (withTrad = true) => {
    let roleList = store.getters.userRestrictedRefs.restrictedRoles || []
    roleList = roleList.map(role => role.code)
    return withTrad ? translateList(roleList, 'user.roleList') : roleList
  },

  getAgenciesNames: () => agencies.map(agency => agency.name).sort(),
  getAgencies: () => agencies,
  getRegions: () => regions,

  getRegionsCodes: () => regions.map(region => region.codeRegion),
  getRegionLabelByCode: (code) => regions.find(region => region.codeRegion === code) ? regions.find(region => region.codeRegion === code)['name'] : null,

  getAlertTypes: () => { return alertTypes },

  getNaturesAssistance: () => { return naturesAssistance },

  getResponsablesAssistance: () => { return responsablesAssistance },

  setLibraryThemes: (newThemes) => { libraryThemes = newThemes },
  getLibraryThemes: () => { return libraryThemes },

  getFeaturesFlipped: () => featuresFlipped,
  isActiveFeatureByCode: (code) => featuresFlipped.find((feature) => feature.code === code) ? featuresFlipped.find((feature) => feature.code === code).active : false,

  getAssistResponsible: (respGc) => respGc ? i18n.t("station.responsible.gareEtCo") : i18n.t("station.responsible.transporter"),

  getCustomerInformationList: () => {
    return customerInformationList
  }
}
