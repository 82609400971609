<template>
  <socamodal
    class="modalJourney modalStationContainer"
    :title="$t('prestation.segmentinfo')"
    @close="closeTrajet"
    @submit="validate">
    <template v-slot:header>
      <div class="modalHeaderInfo hide-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
    </template>
    <div class="modalFormInTitle">{{$t('prestation.station.stationInfo') }}</div>
    <div class="stationBlock">
      <div class="stationSearchSelect" v-if="canChangeStation">
        <Multiselect v-model="stationData" :options="stationsList"
        :searchable="true" :internal-search="false" :options-limit="100"
        @search-change="debouncedSearchStation" @input="selectStation"
        label="name" track-by="codeUic" :placeholder="`${$t('prestation.station.typeToSearch') }`"
        deselectLabel="" selectLabel="" selectedLabel="">
          <template slot="placeholder">{{$t('prestation.station.chooseStation') }}</template>
          <template slot="noOptions">{{$t('prestation.station.searchNoResults') }}</template>
          <template slot="noResult">{{$t('prestation.station.searchNoResults') }}</template>
          <template slot="caret"><svgicon icon="arrow" class="caret" /></template>
          <template slot="option" slot-scope="props">
            <div class="stationOption">
              <span class="stationOptionName">{{ props.option.name }}</span>
              <span v-if="props.option.fromPiv && (props.option.arrivalTime || props.option.departureTime )" class="pivInfo">
                <span v-if="props.option.arrivalTime">{{ props.option.arrivalTime | hour }}</span>
                <svgicon icon="train" />
                <span v-if="props.option.departureTime">{{ props.option.departureTime | hour }}</span>
              </span>
            </div>
          </template>
          <template slot="singleLabel" slot-scope="value">
            <span>{{$t('prestation.station.stationOfX', {station: value.option.name}) }}</span>
          </template>
        </Multiselect>
        <small v-if="validation.hasError('prestation.garePrestaUic')" class="error">{{ $t('error.field.mandatory') }}</small>
      </div>
      <div class="stationBlockName" v-if="!canChangeStation">{{$t('prestation.station.stationOfX', {station: stationData.name}) }}</div>
      <br>
    </div>

    <div class="content-block grid-x grid-margin-x">
      <div class="cell small-12 medium-6">
        <StationInfo :station="stationData"></StationInfo>
      </div>
      <div class="cell small-12 medium-6">
        <div class="formElement cell">
          <label>{{$t('prestation.station.mention') }}</label>
          <select v-model="prestation.mention" :disabled="['D', 'DC'].includes(prestationData.mention)">
            <option v-for="mention in mentions" :value="mention.sigle" :key="mention.sigle">{{mention.label}}</option>
          </select>
          <small v-if="validation.hasError('prestation.mention')" class="error">{{ $t('error.field.mandatory') }}</small>
        </div>

        <div class="cell small-6 formElement datePrestation">
          <label>{{$t('common.date') }}</label>
          <Datepicker v-model="prestation.datePrestation" :available-dates="availableDates"></Datepicker>
          <small v-if="validation.hasError('prestation.datePrestation')" class="error">{{ $t('error.field.mandatory') }}</small>
        </div>
        <div class="cell small-6 formElement datePrestationHour">
          <label>{{$t('common.hour') }}</label>
          <select v-model="datePrestationHour" v-if="passages.length > 0" @change="passageHourChanged()">
            <option v-for="option in passages" :key="option.hour" :value="option.hour">{{ option.isArrival ? $t('common.arrival') : $t('common.departure') }}&nbsp;-&nbsp;{{ option.hour }}</option>
          </select>
          <Timepicker v-model="datePrestationHour" v-if="passages.length == 0"/>
          <small v-if="validation.hasError('datePrestationHour')" class="error">{{ $t('error.field.mandatory') }}</small>
        </div>

<!-- TRAJET -->
        <div class="modalFormInTitle">{{$t('prestation.trajet.trajetInfo') }}</div>
        <div class="formElement position-relative trainNumber">
          <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv"/>
          <label>{{$t('prestation.trajet.trainnumber') }} <small v-if="validation.hasError('prestation.trainNumber')" class="error">{{ $t('error.field.mandatory') }}</small></label>
          <input type="text"
            :placeholder="`${$t('prestation.trajet.trainnumberplaceholder') }`"
            v-model="prestation.trainNumber"
            v-debounce="fetchPivDataTrainNumber"
          />
        </div>
        <div class="formElement position-relative missionCode">
          <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv"/>
          <label>{{$t('prestation.trajet.missioncode') }} <small v-if="validation.hasError('prestation.missionCode')" class="error">{{ $t('error.validator.lengthBetween', {0: 3, 1: 15}) }}</small></label>
          <input type="text"
            :placeholder="`${$t('prestation.trajet.missioncodeplaceholder') }`"
            v-model="prestation.missionCode"
            v-debounce="fetchPivDataMissionCode"
            v-uppercase
          />
        </div>
        <div class="formElement transporter">
          <label>{{$t('prestation.trajet.transporter') }} <small v-if="validation.hasError('prestation.transporter')" class="error">{{ $t('error.field.mandatory') }}</small></label>
          <select v-model="prestation.transporter" >
            <option v-for="transporter in transporters" :value="transporter.code" :key="transporter.code">{{transporter.name}}</option>
          </select>
        </div>
        <div class="formElement service">
          <label>{{$t('prestation.trajet.service') }} <small v-if="validation.hasError('prestation.service')" class="error">{{ $t('error.field.mandatory') }}</small></label>
          <select v-model="prestation.service" disabled>
            <option v-for="service in services" :value="service.code" :key="service.code">{{service.code}}</option>
          </select>
        </div>
        <div class="formElement transportMode">
          <label>{{$t('prestation.trajet.transportMode') }}</label>
          <select v-model="prestation.transportMode" >
            <option v-for="transportMode in transportModes" :value="transportMode" :key="transportMode">{{ $t('common.transportMode.'+$sncfRefs.getTransportModeTechnicalNameByLabel(transportMode)) }}</option>
          </select>
          <small v-if="validation.hasError('prestation.transportMode')" class="error">{{ $t('error.field.mandatory') }}</small>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-6 formElement trainCar">
            <label>{{$t('prestation.trajet.traincar') }}<span v-if="mandatoryCarAndPlace">*</span> <small v-if="validation.hasError('prestation.trainCar')" class="error">{{ validation.firstError('prestation.trainCar') }}</small></label>
            <input type="text"
              :placeholder="`${$t('prestation.trajet.traincarplaceholder') }`"
              v-model="prestation.trainCar"
            />
          </div>
          <div class="cell small-6 formElement trainPlace">
            <label>{{$t('prestation.trajet.trainplace') }}<span v-if="mandatoryCarAndPlace">*</span> <small v-if="validation.hasError('prestation.trainPlace')" class="error">{{ validation.firstError('prestation.trainPlace') }}</small></label>
            <input type="text"
              :placeholder="`${$t('prestation.trajet.trainplaceplaceholder') }`"
              v-model="prestation.trainPlace"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mandatoryElementInfo show-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
  </socamodal>
</template>

<script>
import moment from 'moment'
import common from '@/services/common'
import userService from '@/services/userService'
import pivService from '@/services/piv'
import StationInfo from '@/components/Stations/Info'
import Datepicker from '@/components/Common/Datepicker'
import Timepicker from '@/components/Common/Timepicker'
import Multiselect from 'vue-multiselect/src/Multiselect'
import SimpleVueValidation from 'simple-vue-validator'
import { debounce } from 'simple-vue-validator/src/utils'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'ReportSegmentForm',
  props: { prestationData: Object, availableDates: Object },
  data () {
    return {
      prestation: {},
      datePrestationHour: '',
      stationsSearchResult: [],
      stationData: null,
      mentions: [],
      transporters: [],
      transportModes: [],
      services: [],
      passages: [],
      isLoadingPiv: false,
      pivSearchTrainNumber: null,
      pivSearchMissionCode: null,
      motifs: [],
      statuses: [],
      statusesTN: [],
      stationsFromPiv: []
    }
  },
  created: async function () {
    this.debouncedSearchStation = debounce((query) => this.asyncStationSearch(query), 500)
    this.prestation = Object.assign({}, this.prestationData)
    if (this.prestation.datePrestation) {
      this.datePrestationHour = (new Date(this.prestation.datePrestation)).getHours() + ':' + (new Date(this.prestation.datePrestation)).getMinutes()
    }
    this.mentions = this.$sncfRefs.getMentions()
    this.statusesTN = this.$sncfRefs.getPrestaStatusesTechnicalNames()
    this.transporters = this.$sncfRefs.getTransportersModelsForUpsert()
    this.motifs = this.$sncfRefs.getPrestaReasonsTechnicalNames()
    this.services = this.$sncfRefs.getServicesModelsForUpsert()
    try {
      await this.fetchStationData(this.prestation.garePrestaUic)

      if (!this.canHavePrestation) {
        this.mentions = [this.$sncfRefs.getMentionsByTechnicalName().none]
        this.prestation.mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
      }
    } catch (error) {
      console.error('Error fetching station data for report:', error)
    }
    this.transportModes = this.$sncfRefs.getTransportModes()

    // init stations from piv
    if (this.prestation && this.prestation.pivProposedsStops) {
      let self = this
      pivService.fetchPivStationsFromStops(this.prestation.pivProposedsStops)
        .then(function (stationsWithPiv) { self.stationsFromPiv = stationsWithPiv })
    }
  },
  methods: {
    fetchPivDataTrainNumber () {
      let currentTrainNumber = this.prestation.trainNumber
      if (currentTrainNumber && currentTrainNumber !== this.pivSearchTrainNumber && (currentTrainNumber.length > 3)) { // search by train number
        this.passages = []
        this.isLoadingPiv = true
        this.pivSearchTrainNumber = currentTrainNumber
        pivService.fetchPivDataByTrainNumber(currentTrainNumber, this.prestation.datePrestation, this.prestation.garePrestaUic)
          .then(pivData => {
            if (currentTrainNumber === this.pivSearchTrainNumber) {
              this.getPivData(pivData, 'datafoundinpiv')
            }
          })
          .catch(err => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    fetchPivDataMissionCode () {
      this.prestation.missionCode = this.prestation.missionCode.toUpperCase()
      let currentMissionCode = this.prestation.missionCode
      if (currentMissionCode && currentMissionCode !== this.pivSearchMissionCode && (currentMissionCode.length > 3)) { // search by code mission
        this.passages = []
        this.isLoadingPiv = true
        this.pivSearchMissionCode = currentMissionCode
        pivService.fetchPivDataByMissionCode(currentMissionCode, this.prestation.datePrestation, this.prestation.garePrestaUic)
          .then(pivData => {
            if (currentMissionCode === this.pivSearchMissionCode) {
              this.getPivData(pivData, 'datafoundinpivmissioncode')
            }
          })
          .catch(err => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    getPivData (pivData, type) {
      this.isLoadingPiv = false
      if (pivData.passages.length > 0) {
        this.$toastService.showToast(this.$t('prestation.trajet.' + type), this.$el, 'info')
        let passage = pivData.passages[0]
        this.datePrestationHour = passage.hour
        this.prestation.transporter = passage.transporter
        this.prestation.trainNumber = passage.trainNumber
        if (passage.missionCode) {
          this.prestation.missionCode = passage.missionCode
        }
        this.prestation.dateCirculation = passage.dateCirculation

        for (let i in pivData.passages) {
          passage = pivData.passages[i]
          // on duplique les passages par arrivée et départ de la gare
          if (passage.arrHour && passage.arrHourDateTime) {
            let passageCopyArr = this._.cloneDeep(passage)
            passageCopyArr.hour = passage.arrHour
            passageCopyArr.hourDateTime = passage.arrHourDateTime
            passageCopyArr.isArrival = true
            this.passages.push(passageCopyArr)
          }
          if (passage.depHour && passage.depHourDateTime) {
            let passageCopyDep = this._.cloneDeep(passage)
            passageCopyDep.hour = passage.depHour
            passageCopyDep.hourDateTime = passage.depHourDateTime
            passageCopyDep.isArrival = false
            this.passages.push(passageCopyDep)
          }
        }
      }
    },
    closeTrajet () {
      this.$emit('closeTrajet')
    },
    validate () {
      const self = this
      this.$validate()
        .then(function (success) {
          if (success) {
            self.prestation.datePrestation = common.setHoursMinute(self.datePrestationHour, self.prestation.datePrestation)
            for (const passI in self.passages) {
              if (self.passages[passI].hour === self.datePrestationHour) {
                self.prestation.nextStops = self.passages[passI].nexts;
              }
            }
            if (!self.canHavePrestation) {
              self.prestation.mention = self.$sncfRefs.getMentionsByTechnicalName().none.sigle
              self.mentions = [self.$sncfRefs.getMentionsByTechnicalName().none]
            } else {
              self.mentions = self.$sncfRefs.getMentions()
            }
            self.$emit('savePrestation', self.prestation)
            self.closeTrajet()
          }
        })
        .catch(function (error) {
          console.error("Erreur lors de la validation du formulaire :", error)
        })
    },
    selectStation (valSelected) {
      if (valSelected !== null) {
        this.stationData = valSelected
        this.prestation.garePrestaUic = valSelected.codeUic
        this.prestation.datePrestation = moment(valSelected.arrivalTime).toDate()
        this.datePrestationHour = moment(valSelected.arrivalTime).format('HH:mm')
        if (!this.canHavePrestation) {
          this.prestation.mention = this.$sncfRefs.getMentionsByTechnicalName().none.sigle
          // TBD : See if we must add NO_ELIGIBLE_ASSISTANCE_MOTIF motif for report also
          this.mentions = [this.$sncfRefs.getMentionsByTechnicalName().none]
        } else {
          this.mentions = this.$sncfRefs.getMentions()
        }
      }
    },
    fetchStationData (codeUic) {
      return this.$backendConnector.getGareByUic(codeUic)
        .then(results => (this.stationData = results))
    },
    asyncStationSearch (query) {
      if (query.length > 0) {
        this.$backendConnector.searchStations(query)
          .then(results => {
            this.stationsSearchResult = results.map(v => ({ ...v, fromPiv: false }))
            if (!this.$perms.isUserAllowed('presta.creation.garanteed')) {
              this.stationsSearchResult = this.stationsSearchResult.filters(g => g.spontaneous)
            }
          })
      } else {
        this.stationsSearchResult = []
      }
    },
    passageHourChanged () {
      for (var i in this.passages) {
        let passage = this.passages[i]
        if (passage.hour === this.datePrestationHour) {
          this.prestation.transporter = passage.transporter
          this.prestation.trainNumber = passage.trainNumber
          if (passage.missionCode) {
            this.prestation.missionCode = passage.missionCode
          }
        }
      }
    }
  },
  computed: {
    stationsList: function () {
      if (this.stationsSearchResult.length > 0) {
        return this.stationsSearchResult
      }
      if (this.stationsFromPiv) {
        return this.stationsFromPiv
      }
      return []
    },
    canChangeStation: function () {
      return (this.prestationData.mention !== this.$sncfRefs.getMentionsByTechnicalName().departure)
    },
    mandatoryCarAndPlace() {
      if (this.$store.getters.userRole === 'TRANSPORTER') {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnGarenteed().includes(this.prestation.transporter)
      } else {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous().includes(this.prestation.transporter)
      }
    },
    canHavePrestation: function () {
      let isMentionNRequired = false
      if (this.stationData) {
        isMentionNRequired = this.checkMentionRequirement
      }
      console.log(isMentionNRequired)
      return !isMentionNRequired
    },
    checkMentionRequirement: function() {
      const transporter = this.prestation?.transporter || null

      // Transporters and onboard crew cannot report.
      if (this.isGAndCoOrOther) {
        return common.isMentionNRequiredForOtherUsers(this.stationData, transporter)
      }

      return false
    },
    isGAndCoOrOther() {
      return !userService.isTransporter() && !userService.isOnBoardCrew()
    }
  },
  validators: {
    'prestation.trainNumber': function (value) {
      if (!this.prestation.missionCode) {
        return Validator.value(value).required().lengthBetween(3, 15)
      }
    },
    'prestation.missionCode': function (value) {
      if (!this.prestation.trainNumber) {
        return Validator.value(value).required()
      }
    },
    'prestation.transporter': function (value) {
      return Validator.value(value).required()
    },
    'prestation.service': function (value) {
      return Validator.value(value).required()
    },
    'prestation.transportMode': function (value) {
      return Validator.value(value).required()
    },
    'prestation.datePrestation': function (value) {
      return Validator.value(value).required()
    },
    'datePrestationHour': function (value) {
      return Validator.value(value).required()
    },
    'prestation.trainCar': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value).required().maxLength(30)
      } else if (value) {
        return Validator.value(value).maxLength(30)
      }
    },
    'prestation.trainPlace': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value).required().maxLength(30)
      } else if (value) {
        return Validator.value(value).maxLength(30)
      }
    }
  },
  filters: {
    hour: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  components: {
    StationInfo, Datepicker, Timepicker, Multiselect
  }
}
</script>
