<template>
<div class="infoPanel prestationPanel panelIsCompleted">
  <div class="infoPanelLine botBd infoPanelTitle">
    <span class="stationName">{{ prestation.garePresta.name }}
      <svgicon icon="star" class="prestaGarantedIcon" v-if="prestation.garantee === $sncfRefs.getPrestaTypesByTechnicalName().garanteed" />
    </span>
  </div>
  <div class="infoPanelLine botBd">
    <span>{{ datePrestationLbl  }}</span>
  </div>
  <div class="infoPanelLine">
    <span class="trainHour">{{ hourPrestationLbl  }}</span>
      <TrainNumberBox :prestation="prestation" />
    <div class="panelLineRight">
      <span class="mention">{{prestation.mention}}</span>
      <PrestaAssistBox :element="account" />
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import PrestaAssistBox from '@/components/Common/PrestaAssistBox'
import TrainNumberBox from '@/components/Common/TrainNumberBox'

export default {
  name: 'RecapSegment',
  props: { prestation: Object, account: Object },
  computed: {
    datePrestationLbl: function () {
      if (this.prestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('DD MMMM YYYY')
      }
      return null
    },
    hourPrestationLbl: function () {
      if (this.prestation) {
        return moment(this.prestation.datePrestation).locale(this.$i18n.locale).format('HH:mm')
      }
      return null
    }
  },
  components: { PrestaAssistBox, TrainNumberBox }
}
</script>
