<template>
  <socamodal class="modalJourney" :title="$t('prestation.trajet.trajet')" @close="closeTrajet" @submit="validate">
    <template v-slot:header>
      <div class="modalHeaderInfo hide-for-small-only">{{ $t("common.field.mandatoryFields") }}</div>
    </template>
    <div v-if="!isEdit">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <div class="formElement position-relative trajetTrainnumber">
            <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
            <label>
              {{ $t("prestation.trajet.trainnumber") }}<span v-if="trajet.transportMode !== $sncfRefs.getTransportModesByTechnicalName().taxi">*</span>
              <small v-if="validation.hasError('trajet.trainNumber')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <input type="text" :placeholder="`${$t('prestation.trajet.trainnumber')}`" v-model="trajet.trainNumber" v-debounce="fetchPivDataTrainNumber" :disabled="isOnBoard"/>
          </div>
          <div class="formElement position-relative trajetMissioncode" v-if="!isOnBoard">
            <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
            <label>
              {{ $t("prestation.trajet.missioncode") }}
              <small v-if="validation.hasError('trajet.missionCode')" class="error">{{ validation.firstError("trajet.missionCode") }}</small>
            </label>
            <input type="text" :placeholder="`${$t('prestation.trajet.missioncode')}`" v-model="trajet.missionCode" v-debounce="fetchPivDataMissionCode" v-uppercase />
          </div>
          <div class="formElement trajetTransporter">
            <label>
              {{ $t("prestation.trajet.transporter") }}*
              <small v-if="validation.hasError('trajet.transporter')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <select v-model="trajet.transporter" :disabled="isOnBoard">
              <option v-for="transporter in transporters" :value="transporter.code" :key="transporter.code">{{ transporter.name }}</option>
            </select>
          </div>
          <div class="formElement trajetService" :style="{ visibility: !showServiceField }">
            <label>
              {{ $t("prestation.trajet.service") }}*
              <small v-if="validation.hasError('trajet.service')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <select v-model="trajet.service">
              <option v-for="service in services" :value="service.code" :key="service.code">{{ service.code }}</option>
            </select>
          </div>
        </div>
        <div class="cell small-12 medium-6">
          <div class="formElement trajetTransportMode">
            <label>
              {{ $t("prestation.trajet.transportMode") }}*
              <small v-if="validation.hasError('trajet.transportMode')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <select v-model="trajet.transportMode">
              <option v-for="transportMode in transportModes" :value="transportMode" :key="transportMode">
                {{ $t("common.transportMode." + $sncfRefs.getTransportModeTechnicalNameByLabel(transportMode)) }}
              </option>
            </select>
          </div>
          <div class="formElement trajetTrainDeparture">
            <label>
              <span
                v-tooltip="{
                  content: 'Vous ne pouvez créer de prestations seulement à J et J+1',
                  trigger: 'hover click',
                }"
              >
                {{ $t("common.date") }}*
              </span>
              <small v-if="validation.hasError('trajet.datePrestation')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <Datepicker v-model="trajet.datePrestation" :available-dates="availableDates"></Datepicker>
          </div>
          <div class="formElement trajetTrainDepartureHour">
            <label>
              {{ $t("common.hour") }}*
              <small v-if="validation.hasError('datePrestationHour')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <select v-model="datePrestationHour" v-if="trajet.passages.length > 0" @change="passageHourChanged()">
              <option v-for="option in trajet.passages" :key="option.hour" :value="option.hour">
                {{ option.isArrival ? $t("common.arrival") : $t("common.departure") }}&nbsp;-&nbsp;{{ option.hour }}
              </option>
            </select>
            <Timepicker v-model="datePrestationHour" v-if="trajet.passages.length == 0" />
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell small-6 formElement trajetTrainCar">
              <label>
                {{ $t("prestation.trajet.traincar") }}<span v-if="mandatoryCarAndPlace">*</span>
                <small v-if="validation.hasError('trajet.trainCar')" class="error">{{ validation.firstError("trajet.trainCar") }}</small>
              </label>
              <input type="text" :placeholder="`${$t('prestation.trajet.traincar')}`" v-model="trajet.trainCar" />
            </div>
            <div class="cell small-6 formElement trajetTrainPlace">
              <label>
                {{ $t("prestation.trajet.trainplace") }}<span v-if="mandatoryCarAndPlace">*</span>
                <small v-if="validation.hasError('trajet.trainPlace')" class="error">{{ validation.firstError("trajet.trainPlace") }}</small>
              </label>
              <input type="text" :placeholder="`${$t('prestation.trajet.trainplace')}`" v-model="trajet.trainPlace" />
            </div>
          </div>
        </div>

        <div class="cell small-12">
          <br />
          <div class="grid-x grid-margin-x" v-if="garanteedOnly">
            <div class="formElement transporterDateAsked cell small-6">
              <label>
                {{ $t("prestation.transporter.dateAsked") }}<span>*</span>
                <small v-if="validation.hasError('trajet.dateAsked')" class="error">{{ $t("error.field.mandatory") }}</small>
              </label>
              <Datepicker v-model="trajet.dateAsked"></Datepicker>
            </div>
            <div class="formElement transporterHourAsked cell small-6">
              <label>
                {{ $t("prestation.transporter.hourAsked") }}<span>*</span>
                <small v-if="validation.hasError('trajet.hourAsked')" class="error">{{ $t("error.field.mandatory") }}</small>
              </label>
              <Timepicker v-model="trajet.hourAsked" />
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell small-12 medium-6" v-if="garanteedOnly">
              <br class="hide-for-small-only" />
              <small v-if="errorGarantee" class="error">{{ $t("error.field.garantee") }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="mandatoryElementInfo show-for-small-only">{{ $t("common.field.mandatoryFields") }}</div>
    </div>
    <div v-else>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6">
          <div class="formElement position-relative trajetTrainnumber">
            <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
            <label>
              {{ $t("prestation.trajet.trainnumber") }}<span v-if="trajet.transportMode !== $sncfRefs.getTransportModesByTechnicalName().taxi">*</span>
              <small v-if="validation.hasError('trajet.trainNumber')" class="error">{{ $t("error.field.mandatory") }}</small>
            </label>
            <input type="text" :placeholder="`${$t('prestation.trajet.trainnumber')}`" v-model="trajet.trainNumber" @input="fetchPivDataTrainNumber()" />
          </div>
          <div class="formElement position-relative trajetMissioncode">
            <svgicon icon="loader" class="fieldLoader" v-if="isLoadingPiv" />
            <label>
              {{ $t("prestation.trajet.missioncode") }}
              <small v-if="validation.hasError('trajet.missionCode')" class="error">{{ validation.firstError("trajet.missionCode") }}</small>
            </label>
            <input type="text" :placeholder="`${$t('prestation.trajet.missioncode')}`" v-model="trajet.missionCode" @input="fetchPivDataMissionCode()" />
          </div>
        </div>
        <div class="cell small-12 medium-6">
          <div class="grid-x grid-margin-x">
            <div class="cell small-6 formElement trajetTrainCar">
              <label>
                {{ $t("prestation.trajet.traincar") }}<span v-if="mandatoryCarAndPlace">*</span>
                <small v-if="validation.hasError('trajet.trainCar')" class="error">{{ $t("error.field.mandatory") }}</small>
              </label>
              <input type="number" min="1" :placeholder="`${$t('prestation.trajet.traincar')}`" v-model="trajet.trainCar" />
            </div>
            <div class="cell small-6 formElement trajetTrainPlace">
              <label>
                {{ $t("prestation.trajet.trainplace") }}<span v-if="mandatoryCarAndPlace">*</span>
                <small v-if="validation.hasError('trajet.trainPlace')" class="error">{{ $t("error.field.mandatory") }}</small>
              </label>
              <input type="number" min="1" :placeholder="`${$t('prestation.trajet.trainplace')}`" v-model="trajet.trainPlace" />
            </div>
          </div>
        </div>
      </div>
      <div class="mandatoryElementInfo">{{ $t("common.field.mandatoryFields") }}</div>
    </div>
  </socamodal>
</template>

<script>
import moment from 'moment'
import Datepicker from '@/components/Common/Datepicker'
import validationPrestaCreation from '@/services/validatePrestationCreation'
import common from '@/services/common'
import pivService from '@/services/piv'
import userService from '@/services/userService'
import SimpleVueValidation from 'simple-vue-validator'
import Timepicker from '@/components/Common/Timepicker'
import { SPONTANE, journeyDataObject } from '@/utils/constantsUtils'

const Validator = SimpleVueValidation.Validator

export default {
  name: 'JourneyForm',
  props: { journeyData: Object, stationDataFrom: Object, segmentsIndex: Number, journeyDate: Date },
  data () {
    return {
      transporters: null,
      services: null,
      transportModes: null,
      errorGarantee: false,
      showServiceField: true,
      datePrestationHour: null,
      trajet: journeyDataObject,
      availableDates: {
        start: null,
        end: null
      },
      isLoadingPiv: false,
      pivSearchTrainNumber: null,
      pivSearchMissionCode: null,
      readyToPiv: false,
      pageLoaded: false,
      isEdit: false,
      userRole: null,
      allTransporters: null
    }
  },
  mounted () {
    this.userRole = this.$store.getters.userRole
    this.trajet = JSON.parse(JSON.stringify(journeyDataObject))
    this.setTransporters()
    this.transportModes = this.$sncfRefs.getTransportModes()
    this.trajet.transportMode = this.$sncfRefs.getTransportModesByTechnicalName().train
    this.$store.commit('resetNextStations')
    this.trajet.datePrestation = new Date()
    this.trajet.dateAsked = new Date()
    if (this.journeyData) {
      this.trajet = Object.assign(this.trajet, this._.cloneDeep(this.journeyData))
      this.trajet.datePrestation = new Date(this.trajet.datePrestation)
      this.trajet.dateAsked = new Date(this.trajet.dateAsked)

      this.datePrestationHour = this.trajet.isNew ? '' : moment(this.trajet.datePrestation).format('HH:mm')
      this.trajet.hourAsked = moment(this.trajet.dateAsked).format('HH:mm')
      this.isEdit = this.$perms.isUserAllowed('presta.edit') && this.journeyData.statusTN === 'todo' && this.journeyData.herokuExternalId
    }

    this.loadServices()
    this.trajet.transportMode = this.trajet.transportMode || this.$sncfRefs.getTransportModesByTechnicalName().train
    if (this.garanteedOnly) {
      this.trajet.garantee = this.$sncfRefs.getPrestaTypesByTechnicalName().garanteed
    } else {
      this.trajet.garantee = this.$sncfRefs.getPrestaTypesByTechnicalName().notGaranteed
    }
    this.setAvailableDates()

    this.readyToPiv = true
    const self = this
    this.$nextTick(() => {
      self.pageLoaded = true
    })
    if (this.isOnBoard) {
      this.setOnboardData()
    }
  },
  methods: {
    loadServices () {
      this.services = this.$sncfRefs.getServicesModelsForUpsert()
      this.showServiceField = true
      if (!this.$perms.isUserAllowed('presta.creation.garanteed')) {
        this.services = [this.services.find((s) => s.code === SPONTANE)]
        this.trajet.service = SPONTANE
        this.showServiceField = false
      }
    },
    setAvailableDates () {
      // La date de départ est au minimum la date du premier segment
      if (this.journeyDate && this.segmentsIndex !== 0) {
        this.availableDates.start = new Date(this.journeyDate)
        // Départ en jour J
      } else {
        this.availableDates.start = new Date()
      }

      if (this.journeyDate && this.segmentsIndex !== 0) {
        this.availableDates.end = moment(new Date(this.journeyDate))
          .add('1', 'days')
          .toDate()
      } else if (!this.$perms.isUserAllowed('presta.creation.date.end.any')) {
        this.availableDates.end = moment()
          .add('1', 'days')
          .toDate()
      }
    },
    fetchPivDataTrainNumber () {
      let currentTrainNumber = this.trajet.trainNumber
      if (this.readyToPiv && currentTrainNumber && currentTrainNumber !== this.pivSearchTrainNumber && currentTrainNumber.length > 3) {
        // search by train number
        this.trajet.passages = []
        this.isLoadingPiv = true
        this.pivSearchTrainNumber = currentTrainNumber
        pivService
          .fetchPivDataByTrainNumber(currentTrainNumber, this.trajet.datePrestation, this.stationDataFrom.codeUic)
          .then((pivData) => {
            if (this.pivSearchTrainNumber === currentTrainNumber) {
              if (this.isOnBoard) {
                this.$store.commit('nextStations', pivData.passages[0].nexts)
              }
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                if (['USER', 'ADMINISTRATOR', 'REFERENT'].includes(this.userRole) && !this.handleTransporterValidation(pivData.passages[0])) {
                  return;
                }

                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpiv'), this.$el, 'info')
                this.onFetchPivPassage(pivData)
                if (pivData.passages[0].missionCode) {
                  this.trajet.missionCode = pivData.passages[0].missionCode
                }
              }
            }
          })
          .catch((err) => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    fetchPivDataMissionCode () {
      this.trajet.missionCode = this.trajet.missionCode.toUpperCase()
      let currentMissionCode = this.trajet.missionCode
      if (this.readyToPiv && currentMissionCode && currentMissionCode !== this.pivSearchMissionCode && currentMissionCode.length > 2) {
        // search by code mission
        this.trajet.passages = []
        this.isLoadingPiv = true
        this.pivSearchMissionCode = currentMissionCode
        pivService
          .fetchPivDataByMissionCode(currentMissionCode, this.trajet.datePrestation, this.stationDataFrom.codeUic)
          .then((pivData) => {
            if (this.pivSearchMissionCode === currentMissionCode) {
              this.isLoadingPiv = false
              if (pivData.passages.length > 0) {
                if (['USER', 'ADMINISTRATOR', 'REFERENT'].includes(this.userRole) && !this.handleTransporterValidation(pivData.passages[0])) {
                  return;
                }

                this.$toastService.showToast(this.$t('prestation.trajet.datafoundinpivmissioncode'), this.$el, 'info')
                this.onFetchPivPassage(pivData)
                this.trajet.trainNumber = pivData.passages[0].trainNumber
              }
            }
          })
          .catch((err) => {
            this.isLoadingPiv = false
            this.$toastService.showToast(err.message, this.$el, 'warning')
          })
      }
    },
    onFetchPivPassage (pivData) {
      const passage = pivData.passages[0]

      let updatedJourneyData = { ...this.trajet }

      this.datePrestationHour = passage.hour
      updatedJourneyData.transporter = passage.transporter

      for (const passage of pivData.passages) {
        if (passage.arrHour && passage.arrHourDateTime) {
          updatedJourneyData = common.duplicatePassages(
            updatedJourneyData, passage, passage.arrHour, passage.arrHourDateTime, true
          )
        }

        if (passage.depHour && passage.depHourDateTime) {
          updatedJourneyData = common.duplicatePassages(
            updatedJourneyData, passage, passage.depHour, passage.depHourDateTime, false
          )
        }
        updatedJourneyData.dateCirculation = passage.dateCirculation
      }

      this.trajet = { ...updatedJourneyData }
    },
    passageHourChanged () {
      for (var i in this.trajet.passages) {
        let passage = this.trajet.passages[i]
        if (passage.hour === this.datePrestationHour) {
          this.trajet.transporter = passage.transporter
          this.trajet.trainNumber = passage.trainNumber
          if (passage.missionCode) {
            this.trajet.missionCode = passage.missionCode
          }
        }
      }
    },
    closeTrajet () {
      this.$emit('closeTrajet')
    },
    validate () {
      this.readyToPiv = false
      if (!this.trajet.datePrestation || !this.datePrestationHour) {
        this.$toastService.showToast(this.$t('prestation.missing.datehour'), this.$el, 'warning')
      }
      if (this.datePrestationHour) {
        this.trajet.datePrestation = common.setHoursMinute(this.datePrestationHour, this.trajet.datePrestation)
      }
      if (this.trajet.hourAsked) {
        this.trajet.dateAsked = common.setHoursMinute(this.trajet.hourAsked, this.trajet.dateAsked)
      }
      const self = this
      this.$validate()
        .then(function (success) {
          if (success && (self.isEdit || !self.errorGarantee)) {
            for (const passI in self.trajet.passages) {
              if (self.trajet.passages[passI].hour === self.datePrestationHour) {
                self.trajet.nextStops = self.trajet.passages[passI].nexts
              }
            }
            self.$emit('saveJourney', self.trajet)
            self.closeTrajet()
          } else {
            self.readyToPiv = true
          }
        })
        .catch((err) => console.log(err))
    },
    validateGarantee () {
      if (this.garanteedOnly) {
        const isGarantee = validationPrestaCreation.garantee(this.trajet)
        if (isGarantee) {
          this.errorGarantee = false
        } else {
          this.errorGarantee = true
        }
      }
    },
    buildDatePrestation (value) {
      if (this.datePrestationHour) {
        this.trajet.datePrestation = common.setHoursMinute(this.datePrestationHour, value)
      } else {
        this.trajet.datePrestation = value
      }
    },
    setTransporters() {
      this.allTransporters = this.$sncfRefs.getTransportersModelsForUpsert()
      let filtredTransporters = this.allTransporters
      if (['USER', 'ADMINISTRATOR', 'REFERENT'].includes(this.userRole)) {
        filtredTransporters = filtredTransporters.filter(t => {
          return !this.stationDataFrom?.assistActors?.some(aa => aa.transporterCode === t.code)
        })
      }
      this.transporters = filtredTransporters
    },
    setOnboardData() {
      this.trajet.trainNumber = this.$store.getters.trainNumber
      this.trajet.transporter = this.$store.getters.onBoardTransporter
      this.fetchPivDataTrainNumber()
    },
    handleTransporterValidation(passage) {
      const transporterCode = passage.transporter;
      const transporters = this.$sncfRefs
        .getTransportersModelsForUpsert()
        .filter(transporter =>
          !this.stationDataFrom?.assistActors?.some(actor => actor.transporterCode === transporter.code)
        );

      const isValidTransporter = transporters.some(transporter => transporter.code === transporterCode);

      if (!isValidTransporter) {
        // If journey AC - DC do not display error message and add the missing transporter
        if (this.segmentsIndex > 0 && transporterCode) {
          const missingTransporter = this.allTransporters.find(
            transporter => transporter.code === transporterCode
          );
          this.transporters = [...this.transporters, missingTransporter];

          return true;
        }

        this.trajet.transporter = null
        this.$toastService.showToast(
          this.$t('prestation.trajet.cannotCreatePrestationForTransporter'),
          this.$el,
          'error'
        );
        return false;
      }

      return true;
    },
  },
  watch: {
    // when putting all thos fields with a custom validator, the error message are broken so I had to to watchers
    'trajet.dateAsked': function (val) {
      this.validateGarantee()
    },
    'trajet.hourAsked': function (val) {
      // this.validateGarantee()
      if (this.trajet.hourAsked) {
        this.trajet.dateAsked = common.setHoursMinute(this.trajet.hourAsked, this.trajet.dateAsked)
      }
    },
    'trajet.garantee': function (val) {
      this.validateGarantee()
    },
    'trajet.service': function (newVal, oldVal) {
      this.validateGarantee()
    },
    'trajet.datePrestation': function (val) {
      this.validateGarantee()
    },
    datePrestationHour: function (val) {
      // this.validateGarantee()
      if (this.datePrestationHour) {
        this.trajet.datePrestation = common.setHoursMinute(this.datePrestationHour, this.trajet.datePrestation)
      }
    },
    'trajet.transporter': function (newVal, oldVal) {
      this.validateGarantee()
    }
  },
  computed: {
    garanteedOnly: function () {
      // Transporter
      return this.$perms.isUserAllowed('presta.creation.garanteed.only')
    },
    garanteedField: function () {
      // ADMINNAT AU ADMIN USER
      return this.$perms.isUserAllowed('presta.management')
    },
    isOnBoard() {
      return userService.isOnBoardCrew()
    },
    mandatoryCarAndPlace() {
      if (this.userRole === 'TRANSPORTER') {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnGarenteed().includes(this.trajet.transporter) && this.trajet.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi
      } else {
        return this.$sncfRefs.getTransportersCodesWithMandatoryCarAndPlaceOnSpontaneous().includes(this.trajet.transporter) && this.trajet.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi
      }
    }
  },
  validators: {
    'trajet.trainNumber': function (value) {
      if (!this.trajet.missionCode && this.trajet.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi) {
        return Validator.value(value).required()
      }
    },
    'trajet.missionCode': function (value) {
      if (!this.trajet.trainNumber && this.trajet.transportMode !== this.$sncfRefs.getTransportModesByTechnicalName().taxi) {
        return Validator.value(value)
          .required()
          .lengthBetween(3, 15)
      }
    },
    'trajet.transporter': function (value) {
      return Validator.value(value).required()
    },
    'trajet.service': function (value) {
      return Validator.value(value).required()
    },
    'trajet.transportMode': function (value) {
      return Validator.value(value).required()
    },
    'trajet.datePrestation': function (value) {
      return Validator.value(value).required()
    },
    datePrestationHour: function (value) {
      return Validator.value(value).required()
    },
    'trajet.trainCar': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value)
          .required()
          .maxLength(30)
      } else {
        return Validator.value(value).maxLength(30)
      }
    },
    'trajet.trainPlace': function (value) {
      if (this.mandatoryCarAndPlace) {
        return Validator.value(value)
          .required()
          .maxLength(30)
      } else {
        return Validator.value(value).maxLength(30)
      }
    },
    'trajet.dateAsked': function (value) {
      if (this.garanteedOnly) {
        return Validator.value(value).required()
      }
    },
    'trajet.hourAsked': function (value) {
      if (this.garanteedOnly) {
        return Validator.value(value).required()
      }
    }
  },
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.toUpperCase()
      }
    }
  },
  components: {
    Datepicker,
    Timepicker
  }
}
</script>
