<template>
  <div v-if="station">
    <div class='socaContent socaContentNarrow'>
      <div class="pageTitleLight">{{$t('stations.fileupdate') }}</div>
      <div class="stationHeader">
        <h2 class="stationName">{{ station.name }}</h2>
        <div class="regionName">{{ $sncfRefs.getRegionLabelByCode(station.region) }}</div>
      </div>
      <div class="resumeCards grid-x grid-margin-x">
          <div class="resume cell large-6 small-12" v-if='station.spontaneous || station.garanteed || station.isPMR || station.soca'>
            <div class="resumeColumns">
              <div class="resumeColumn">
                <div class="resumeLine resumeLineTitle">
                  <svgicon icon="logo_soca"/>
                  <span>{{ $t('station.resumeStationTitle') }}</span>
                </div>
                <div class="resumeLine" v-if="station.spontaneous">
                  <div class="spontaneousIcon" ><svgicon icon="thunder"/></div>
                  <span>{{ $t('station.spontaneous') }}</span>
                </div>
                <div class="resumeLine"  v-if="station.garanteed">
                  <div class="garanteedIcon"><svgicon icon="star" /></div>
                  <span>{{ $t('station.garanteed') }}</span>
                </div>
                <div class="resumeLine" v-if="station.soca">
                  <div class="socaIcon">SOCA</div>
                  <span>{{ $t('station.useSoca') }}</span>
                </div>
                <div class="resumeLine" v-if="station.isPMR">
                  <div class="pmrIcon">PMR</div>
                  <span>{{ $t('station.pmr') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="resume cell large-6 small-12">
            <div class="resumeColumns">
              <div class="resumeColumn">
                <div class="resumeLine resumeLineTitle">
                  <svgicon icon="casquette"/>
                  <span>{{ $t('station.resumeBoardTitle') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_FULL_ACCESSIBILITY">
                  <svgicon class="icons fullAccessibilityStation" icon="accessible_for_all"/>
                  <span>{{ $t('station.full_accessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_PARTIAL_ACCESSIBILITY">
                  <svgicon class="icons partialAccessibilityStation" icon="accessible"/>
                  <span>{{ $t('station.partial_accessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_LIMITED_ACCESSIBILITY">
                  <svgicon class="icons limitedAccessibilityStation" icon="barely_accessible"/>
                  <span>{{ $t('station.limited_accessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.accessibilityLevel === STATION_NO_ACCESSIBILITY || station.accessibilityLevel === null">
                  <svgicon class="icons noAccessibilityStation" icon="inaccessible"/>
                  <span>{{ $t('station.no_accessibility') }}</span>
                </div>
                <div class="resumeLine" v-if="station.compatibleHardware">
                  <svgicon class="icons hardware" icon="hardware"/>
                  <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ station.compatibleHardware }}</span>
                </div>
                <div class="resumeLine" v-else>
                  <svgicon class="icons hardware" icon="hardware"/>
                  <span class="compatibleHardware">{{ $t('station.compatibleHardware') + ' : ' }}&nbsp;{{ $t('station.noComptibleHardware') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="accordionContainer socaContent socaContentNarrow socaContent-eatContentPadding">
      <ul class="accordion" data-accordion data-allow-all-closed="true">
        <li class="accordion-item  is-active" data-accordion-item>
          <a href="#" class="accordion-title">{{ $t('station.information')}}<svgicon icon="arrow" class="arrow" /></a>
          <div class="accordion-content" data-tab-content>
            <div class="grid-x grid-margin-x">
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.nameOfficial') }}</label>
                <span class="value">{{station.sfName || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.socaName') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.name" v-if="canEditField('name')" />
                <span class="value" v-if="canReadField('name') && !canEditField('name')">{{station.name || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.codeUic') }}</label>
                <span class="value">{{station.codeUic || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.type') }} <span v-if="shouldEditBasicInfos">*</span> <small v-if="validation.hasError('station.type')" class="error">{{ validation.firstError('station.type') }}</small></label>
                <selectpl v-model="station.type">
                  <option value="Gare">{{$t('common.station')}}</option>
                  <option value="Halte">{{$t('common.stop')}}</option>
                </selectpl>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.garanteed') }} <span v-if="shouldEditBasicInfos">*</span> <small v-if="validation.hasError('station.garanteed')" class="error">{{ validation.firstError('station.garanteed') }}</small></label>
                  <div class="formRadio" v-if="canEditField('garanteed')">
                    <label><input type="radio" id="true" value="true" v-model="station.garanteed"><span>{{$t('common.yes') }}</span></label>
                    <label><input type="radio" id="false" value="false" v-model="station.garanteed"><span>{{$t('common.no') }}</span></label>
                  </div>
                  <span class="value" v-if="canReadField('garanteed') && !canEditField('garanteed')">{{ $t('common.answer.' + station.garanteed) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('common.stationAccessibilityLevel')}} <span v-if="shouldEditBasicInfos && canModifyAccessibility">*</span> <small v-if="validation.hasError('station.accessibilityLevel')" class="error">{{ validation.firstError('station.accessibilityLevel') }}</small></label>
                <selectpl v-model="station.accessibilityLevel" v-if="canModifyAccessibility">
                  <option :value="STATION_FULL_ACCESSIBILITY">{{$t('station.full_accessibility')}}</option>
                  <option :value="STATION_PARTIAL_ACCESSIBILITY">{{$t('station.partial_accessibility')}}</option>
                  <option :value="STATION_LIMITED_ACCESSIBILITY">{{$t('station.limited_accessibility')}}</option>
                  <option :value="STATION_NO_ACCESSIBILITY">{{$t('station.no_accessibility')}}</option>
                </selectpl>
                <span class="value" v-else>{{station.accessibilityLevel || $t('station.no_accessibility')}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.spontaneous') }} <span v-if="shouldEditBasicInfos">*</span> <small v-if="validation.hasError('station.spontaneous')" class="error">{{ validation.firstError('station.spontaneous') }}</small></label>
                  <div class="formRadio" v-if="canEditField('spontaneous')">
                    <label><input type="radio" id="true" value="true" v-model="station.spontaneous"><span>{{$t('common.yes') }}</span></label>
                    <label><input type="radio" id="false" value="false" v-model="station.spontaneous"><span>{{$t('common.no') }}</span></label>
                  </div>
                  <span class="value" v-if="canReadField('spontaneous') && !canEditField('spontaneous')">{{ $t('common.answer.' + station.spontaneous) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.services') }}</label>
                <Multiselect v-model="station.services" v-if="canEditField('services')"
                    :options="services.map(s => s.code)" :multiple="true" :searchable="false" :show-labels="false"
                    :placeholder="`${$t('common.select') }`"></Multiselect>
                <span class="value" v-if="canReadField('services') && !canEditField('services')">{{ (station.services && station.services.join) ? station.services.join(' / ') : ''  }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.pmr') }} <span v-if="shouldEditBasicInfos">*</span></label>
                  <div class="formRadio" v-if="canEditField('isPMR')">
                    <label><input type="radio" id="true" :value="true" v-model="station.isPMR"><span>{{$t('common.yes') }}</span></label>
                    <label><input type="radio" id="false" :value="false" v-model="station.isPMR"><span>{{$t('common.no') }}</span></label>
                  </div>
                  <span class="value" v-if="canReadField('isPMR') && !canEditField('isPMR')">{{ $t('common.answer.' + station.isPMR) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.soca') }} <span v-if="shouldEditBasicInfos">*</span> <small v-if="validation.hasError('station.soca')" class="error">{{ validation.firstError('station.soca') }}</small></label>
                  <div class="formRadio" v-if="canEditField('soca')">
                    <label><input type="radio" id="true" value="true" v-model="station.soca"><span>{{$t('common.yes') }}</span></label>
                    <label><input type="radio" id="false" value="false" v-model="station.soca"><span>{{$t('common.no') }}</span></label>
                  </div>
                  <span class="value" v-if="canReadField('soca') && !canEditField('soca')">{{ $t('common.answer.' + station.soca) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.deploySoca') }}  <small v-if="validation.hasError('station.deploySoca')" class="error">{{ validation.firstError('station.deploySoca') }}</small></label>
                <span class="value">{{station.deploySoca || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.stopAndGo') }}  <small v-if="validation.hasError('station.stopAndGo')" class="error">{{ validation.firstError('station.stopAndGo') }}</small></label>
                  <div class="formRadio" v-if="canEditField('stopAndGo')">
                    <label><input type="radio" id="true" value="true" v-model="station.stopAndGo"><span>{{$t('common.yes') }}</span></label>
                    <label><input type="radio" id="false" value="false" v-model="station.stopAndGo"><span>{{$t('common.no') }}</span></label>
                  </div>
                  <span class="value" v-if="canReadField('stopAndGo') && !canEditField('stopAndGo')">{{ $t('common.answer.' + station.stopAndGo) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.extraInfo') }}</label>
                <textarea :placeholder="$t('common.fill')" v-model="station.extraInfo" rows="5" cols="33"></textarea>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.extraInfoGuarantee') }}</label>
                <textarea :placeholder="$t('common.fill')" v-model="station.extraInfoGuarantee" rows="5" cols="33"></textarea>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.externalProvider') }}</label>
                <div class="formRadio" v-if="canEditField('externalProvider')">
                  <label><input type="radio" id="true" value="true" v-model="station.externalProvider"><span>{{$t('common.yes') }}</span></label>
                  <label><input type="radio" id="false" value="false" v-model="station.externalProvider"><span>{{$t('common.no') }}</span></label>
                </div>
                <span class="value" v-if="canReadField('externalProvider') && !canEditField('externalProvider')">{{ $t('common.answer.' + station.externalProvider) }}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.link') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.link" v-if="canEditField('link')" />
                <span class="value" v-if="canReadField('link') && !canEditField('name')">{{station.link || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6 multiselect-gare">
                <label>{{ $t('station.backupStation') }} 1</label>
                <SearchStationMultiselect @updateBackupStation="selectBackup" :gareRabattement="station.gareRabattement1" :whichBackup="1"/>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.tripDuration') }} 1</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.tripToBackupStation1" v-if="canEditField('name')" />
                <span class="value" v-if="canReadField('tripToBackupStation1') && !canEditField('name')">{{station.tripToBackupStation1 || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6 multiselect-gare">
                <label>{{ $t('station.backupStation') }} 2</label>
                <SearchStationMultiselect @updateBackupStation="selectBackup" :gareRabattement="station.gareRabattement2" :whichBackup="2"/>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.tripDuration') }} 2</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.tripToBackupStation2" v-if="canEditField('name')" />
                <span class="value" v-if="canReadField('tripToBackupStation2') && !canEditField('name')">{{station.tripToBackupStation2 || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6 multiselect-gare">
                <label>{{ $t('station.backupStation') }} 3</label>
                <SearchStationMultiselect @updateBackupStation="selectBackup" :gareRabattement="station.gareRabattement3" :whichBackup="3"/>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.tripDuration') }} 3</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.tripToBackupStation3" v-if="canEditField('name')" />
                <span class="value" v-if="canReadField('tripToBackupStation3') && !canEditField('name')">{{station.tripToBackupStation3 || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.country') }} <small v-if="validation.hasError('station.region')" class="error">{{ validation.firstError('station.region') }}</small></label>
                <span class="value">{{station.country || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.region') }} <span v-if="shouldEditRegion">*</span></label>
                <span class="value">{{$sncfRefs.getRegionLabelByCode(station.region) || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.agency') }} <span v-if="shouldEditBasicInfos">*</span> <small v-if="validation.hasError('station.agency')" class="error">{{ validation.firstError('station.agency') }}</small></label>
                <span class="value">{{station.agency || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.accessibility') }}</label>
                <selectpl v-model="station.accessibility">
                  <option value="SDNA">{{$t('station.access.SDNA')}}</option>
                  <option value="SDRAprioComp">{{$t('station.access.SDRAprioComp')}}</option>
                  <option value="OtherStationNoprioNonComp">{{$t('station.access.OtherStationNoprioNonComp')}}</option>
                </selectpl>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.oldRegion') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.oldRegion" v-if="canEditField('oldRegion')" />
                <span class="value" v-if="canReadField('oldRegion') && !canEditField('oldRegion')">{{station.oldRegion || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.axis') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.axis" v-if="canEditField('axis')"/>
                <span class="value" v-if="canReadField('axis') && !canEditField('axis')">{{station.axis || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.ligne') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.line" v-if="canEditField('line')"/>
                <span class="value" v-if="canReadField('line') && !canEditField('line')">{{station.line || '-'}}</span>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.boardingAid') }} <span v-if="shouldEditHours">*</span></label>
                <div class="formRadio">
                  <label><input type="radio" id="true" value="true" v-model="station.boardingAid"><span>{{$t('common.yes') }}</span></label>
                  <label><input type="radio" id="false" value="false" v-model="station.boardingAid"><span>{{$t('common.no') }}</span></label>
                </div>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.meetingPoint') }}</label>
                <input :placeholder="$t('common.fill')" type="text" v-model="station.meetingPoint" />
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.meetingPointTaxi') }}</label>
                <textarea :placeholder="$t('common.fill')" v-model="station.meetingPointTaxi" rows="1" cols="33"></textarea>
              </div>
              <div class="formElement cell small-12 large-6">
                <label>{{ $t('station.planningAcquit') }}</label>
                <div class="formRadio" v-if="canEditField('planningAcquit')">
                  <label><input type="radio" id="true" value="true" v-model="station.planningAcquit"><span>{{$t('common.yes') }}</span></label>
                  <label><input type="radio" id="false" value="false" v-model="station.planningAcquit"><span>{{$t('common.no') }}</span></label>
                </div>
                <span class="value" v-if="canReadField('planningAcquit') && !canEditField('planningAcquit')">{{ $t('common.answer.' + station.planningAcquit) }}</span>
              </div>
            </div>
          </div>
        </li>

        <li class="accordion-item" data-accordion-item>
          <a href="#" class="accordion-title">{{ $t('station.personnel')}}<svgicon icon="arrow" class="arrow" /></a>
          <div class="accordion-content" data-tab-content>
            <h3 v-if="station.assistActors.length === 0">{{ $t('station.noAssistActors') }}</h3>
            <div v-for="assistActor in station.assistActors" :key="assistActor.localId">
              <div class="responsible">
                <span class="resp">
                  {{ $t('station.resp')}}:
                  <span v-if="assistActor.id"> {{ $sncfRefs.getAssistResponsible(assistActor.gcActorCode !== null) }}</span>
                  <span v-else>
                    <selectpl v-model="assistActor.respGc" @input="onResponsibleInput($event === 'true', assistActor)" >
                      <option :value="true">{{$t('station.responsible.gareEtCo')}}</option>
                      <option :value="false">{{$t('station.responsible.transporter')}}</option>
                    </selectpl>
                  </span>
                </span>
                <span class="incumbent" style="margin-left: 10px">
                  {{ $t('station.incumbent')}}: <small v-if="assistActor.hasError.acteur" class="error">{{ $t('error.field.mandatory') }}</small>
                  <span v-if="assistActor.id">{{ assistActor.gcActorCode ? assistActor.gcActor.name : assistActor.transporter.name }}</span>
                  <div v-else>
                    <selectpl v-if="assistActor.respGc" v-model="assistActor.gcActorCode" @input="updateActor($event, assistActor)">
                      <option v-for="gcActor in gcActors" :key="gcActor.code" :value="gcActor.code">{{ gcActor.name }}</option>
                    </selectpl>
                    <selectpl v-else v-model="assistActor.transporterCode" @input="updateTransporter($event, assistActor)">
                      <option v-for="transporter in transporters" :key="transporter.code" :value="transporter.code">{{ transporter.name }}</option>
                    </selectpl>
                  </div>
                </span>
              </div>
              <ul class="accordion" data-accordion data-allow-all-closed="true">
                <li class="accordion-item" data-accordion-item>
                  <a href="#" class="accordion-title">{{ $t('station.coordonnee')}}<svgicon icon="arrow" class="arrow" /></a>
                  <div class="accordion-content" data-tab-content>
                    <div class="grid-x grid-margin-x">
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.leadName') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.leadName" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.leadMail') }} <small v-if="assistActor.hasError.leadMail" class="error">{{ $t('error.field.emailFormat') }}</small></label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.leadMail" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.assistName') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.assistName" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.assistMail') }} <small v-if="assistActor.hasError.assistMail" class="error">{{ $t('error.field.emailFormat') }}</small></label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.assistMail" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.internal1') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.internal1" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.internal2') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.internal2" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.external1') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.external1" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.external2') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.external2" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.mobile1') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.mobile1" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.mobile2') }}</label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.mobile2" />
                      </div>
                      <div class="formElement cell small-12 large-6">
                        <label>{{ $t('station.emailAssist') }} <small v-if="assistActor.hasError.emailAssist" class="error">{{ $t('error.field.emailFormat') }}</small></label>
                        <input :placeholder="$t('common.fill')" type="text" v-model="assistActor.emailAssist" />
                      </div>
                    </div>
                  </div>
                </li>

                <li class="accordion-item agentTimetable" data-accordion-item>
                  <a href="#" class="accordion-title">{{ $t('station.charge')}}<svgicon icon="arrow" class="arrow" /></a>
                  <div class="accordion-content" data-tab-content>
                    <div class="grid-x grid-margin-x">
                      <div class="cell small-12 large-6">
                        <div class="pmrIcons formElement">
                          <div class="pmrIconEdit">
                            <svgicon icon="handicap" class="handicap"/>
                            <div class="handicapName">{{ $t('station.motor') }}</div>
                            <label :for="'stationHandicapMoteur'+assistActor.localId" class="roundCheckbox"><input type="checkbox" :id="'stationHandicapMoteur'+assistActor.localId" v-model="assistActor.handicapMoteur" /><span class="roundCheckboxReplacement" /></label>
                          </div>
                          <div class="pmrIconEdit">
                            <svgicon icon="eye" class="eye"/>
                            <div class="cell small-6 large-6 handicapName">{{ $t('station.visual') }}</div>
                            <label :for="'stationHandicapVisuel'+assistActor.localId" class="roundCheckbox"><input type="checkbox" :id="'stationHandicapVisuel'+assistActor.localId" v-model="assistActor.handicapVisuel" /><span class="roundCheckboxReplacement" /></label>
                          </div>
                          <div class="pmrIconEdit">
                            <svgicon icon="face" class="face"/>
                            <div class="handicapName">{{ $t('station.mental') }}</div>
                            <label :for="'stationHandicapMental'+assistActor.localId" class="roundCheckbox"><input type="checkbox" :id="'stationHandicapMental'+assistActor.localId" v-model="assistActor.handicapMental" /><span class="roundCheckboxReplacement" /></label>
                          </div>
                          <div class="pmrIconEdit">
                            <svgicon icon="ear" class="ear"/>
                            <div class="handicapName">{{ $t('station.audio') }}</div>
                            <label :for="'stationHandicapAudio'+assistActor.localId" class="roundCheckbox"><input type="checkbox" :id="'stationHandicapAudio'+assistActor.localId" v-model="assistActor.handicapAudio" /><span class="roundCheckboxReplacement" /></label>
                          </div>
                          <div class="pmrIconEdit">
                            <svgicon icon="canne" class="canne"/>
                            <div class="handicapName">{{ $t('station.handicapOther') }}</div>
                            <label :for="'stationHandicapAutre'+assistActor.localId" class="roundCheckbox"><input type="checkbox" :id="'stationHandicapAutre'+assistActor.localId" v-model="assistActor.handicapAutre" /><span class="roundCheckboxReplacement" /></label>
                          </div>
                        </div>
                      </div>
                      <div class="cell small-12 large-6">
                        <div v-for="dayConst in daysConstant" :key="dayConst" class="hoursTimepickers timetable">
                          <div class="oneDayTitle">
                            <span class="day">{{ $t('common.' + dayConst) }}</span>
                            <label :for="'station'+assistActor.localId+dayConst+'AgentClosed'" class="hoursTimepickersCloseCheckbox roundCheckbox">
                              <span class="hoursTimepickersCloseCheckboxText">{{ $t('common.closed') }}</span>
                              <input type="checkbox" :id="'station'+assistActor.localId+dayConst+'AgentClosed'" v-model="assistActor[dayConst+'AgentClosed']"  @change="handleAgentDayClosedCheckboxChange(dayConst, assistActor)"/>
                              <span class="roundCheckboxReplacement" />
                            </label>
                          </div>
                          <div class="onePickerDay" v-if="assistActor.daysAgent">
                            <small v-if="daysAgentsErrors[dayConst] && !assistActor[dayConst+'AgentClosed']" class="error">{{ daysAgentsErrors[dayConst] }}</small>
                            <div v-for="(dayVal, fieldName) in assistActor.daysAgent" :key="fieldName">
                              <div class="grid-x grid-margin-x" v-if="fieldName.includes(dayConst) && !assistActor[dayConst+'AgentClosed']">
                                <div class="cell small-6 formElement" :class="'timespicker'+fieldName+'Start'">
                                  <Timepicker  :placeholder="$t('common.from')" v-model="dayVal.start" />
                                </div>
                                <div class="cell small-6 formElement" :class="'timespicker'+fieldName+'End'">
                                  <Timepicker :placeholder="$t('common.to')" v-model="dayVal.end" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="formElement" v-if="assistActor.respGc || assistActor.gcActor">
                          <label>{{ $t('station.assistanceTrain') }} <small v-if="validation.hasError('station.assistanceTrain')" class="error">{{ validation.firstError('station.assistanceTrain') }}</small></label>
                          <div class="formRadio">
                            <label><input type="radio" id="true" value="true" v-model="assistActor.assistanceTrain"><span>{{$t('common.yes') }}</span></label>
                            <label><input type="radio" id="false" value="false" v-model="assistActor.assistanceTrain"><span>{{$t('common.no') }}</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <button v-if="!isAllowedToAddOrDeleteActorAssist" class='button-theme' @click="openModalConfirmDeletion(assistActor)">{{ $t('common.delete') }}</button>
            </div>
            <button v-if="!isAllowedToAddOrDeleteActorAssist" class='button-theme' @click="addAssistActor" style="margin-top: 10px">{{ $t('station.addActor') }}</button>
          </div>
        </li>

        <li class="accordion-item" data-accordion-item>
          <a href="#" class="accordion-title">{{ $t('station.substitution')}}<svgicon icon="arrow" class="arrow" /></a>
          <div class="accordion-content" data-tab-content>
            <div class="grid-x grid-margin-x">
              <div class="cell small-12 large-6">
                <div class="pmrIcons formElement">
                  <div class="pmrIconEdit">
                    <svgicon icon="handicap" class="handicap"/>
                    <div class="handicapName">{{ $t('station.motor') }}</div>
                    <label for="stationHandicapMoteurTaxi" class="roundCheckbox"><input type="checkbox" id="stationHandicapMoteurTaxi" v-model="station.handicapMoteurTaxi" /><span class="roundCheckboxReplacement" /></label>
                  </div>
                  <div class="pmrIconEdit">
                    <svgicon icon="eye" class="eye"/>
                    <div class="cell small-6 large-6 handicapName">{{ $t('station.visual') }}</div>
                    <label for="stationHandicapVisuelTaxi" class="roundCheckbox"><input type="checkbox" id="stationHandicapVisuelTaxi" v-model="station.handicapVisuelTaxi" /><span class="roundCheckboxReplacement" /></label>
                  </div>
                  <div class="pmrIconEdit">
                    <svgicon icon="face" class="face"/>
                    <div class="handicapName">{{ $t('station.mental') }}</div>
                    <label for="stationHandicapMentalTaxi" class="roundCheckbox"><input type="checkbox" id="stationHandicapMentalTaxi" v-model="station.handicapMentalTaxi" /><span class="roundCheckboxReplacement" /></label>
                  </div>
                  <div class="pmrIconEdit">
                    <svgicon icon="ear" class="ear"/>
                    <div class="handicapName">{{ $t('station.audio') }}</div>
                    <label for="stationHandicapAudioTaxi" class="roundCheckbox"><input type="checkbox" id="stationHandicapAudioTaxi" v-model="station.handicapAudioTaxi" /><span class="roundCheckboxReplacement" /></label>
                  </div>
                  <div class="pmrIconEdit">
                    <svgicon icon="canne" class="canne"/>
                    <div class="handicapName">{{ $t('station.handicapOther') }}</div>
                    <label for="stationHandicapAutreTaxi" class="roundCheckbox"><input type="checkbox" id="stationHandicapAutreTaxi" v-model="station.handicapAutreTaxi" /><span class="roundCheckboxReplacement" /></label>
                  </div>
                </div>
              </div>
              <div class="cell small-12 large-6">
                <div class="hoursTimepickers timetable">
                  <div v-for="(dayVal, fieldName) in daysTaxi" :key="fieldName" class="onePickerDay">
                    <span class="day">{{ $t('common.' + fieldName.split('Taxi')[0]) }}</span><br />
                    <div class="grid-x grid-margin-x">
                      <div class="cell small-6 formElement">
                        <Timepicker :placeholder="$t('common.from')" v-model="dayVal.start" />
                      </div>
                      <div class="cell small-6 formElement">
                        <Timepicker :placeholder="$t('common.to')" v-model="dayVal.end" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="accordion-item" data-accordion-item>
         <a href="#" class="accordion-title">{{ $t('station.exceptionalSituations')}}<svgicon icon="arrow" class="arrow" /></a>
          <div class="accordion-content" data-tab-content>
            <div class="grid-x grid-margin-x singleButtonLine">
              <div class="cell small-6 large-4">
                <div @click="addAlert" class="button-round button-round-selected">{{$t('station.alert.createAlert') }}</div>
              </div>
            </div>
            <div class="formElement cell small-12 large-6" v-if="station.alerts.length === 0">
              <label>{{ $t('station.exceptionalSituations') }}</label>
              <div class="formRadio">
                <label>{{$t('common.no') }}</label>
              </div>
            </div>
            <div v-else>
              <Alert @updateAlert="updateAlert" @deleteAlert="openDeleteConfirmation" :alerts="station.alerts" :updatable="true"/>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class='socaContent socaContentNarrow'>
      <button class='button-theme stationSubmitButton' :disabled="isLoading" @click="saveStation">{{$t('station.update')}}</button>
    </div>
    <div v-if="showAlert" v-bind:class="{ hidden: !showAlert }">
      <UpdateAlert @alertUpserted="alertUpserted" @closeAlert="closeAlert" :alertData="alertToUpdate" :codeUic="station.codeUic" :isNewAlert="isNewAlert"/>
    </div>
    <socalightmodal v-if="modalAlertConfirmation" class="operationModalConfirm" :title="$t('station.alert.deleteTitle')" @close="modalAlertConfirmation = false" @submit="deleteAlert">
      <div class="operationModalTxt">
        <svgicon class="alert" icon="alert" />
        {{ $t("station.alert.deleteText") }}
      </div>
    </socalightmodal>
    <socalightmodal v-if="modalActorConfirmation" class="operationModalConfirm" :title="$t('station.actor.deleteActor')" @close="closeModalActor" @submit="deleteAssistActor(selectedActor)">
      <div class="operationModalTxt">
        <svgicon class="alert" icon="alert" />
        {{ $t("station.actor.deleteActorConfirmation") }}
      </div>
    </socalightmodal>
  </div>
</template>

<script>
import Timepicker from '@/components/Common/Timepicker'
import SimpleVueValidation from 'simple-vue-validator'
import Multiselect from 'vue-multiselect/src/Multiselect'
import { STATION_FULL_ACCESSIBILITY, STATION_PARTIAL_ACCESSIBILITY, STATION_LIMITED_ACCESSIBILITY, STATION_NO_ACCESSIBILITY } from '@/utils/constantsUtils'
import userService from '@/services/userService'
import UpdateAlert from "@/components/Stations/UpdateAlert"
import Alert from "@/components/Stations/Alert"
import SearchStationMultiselect from "@/components/Stations/SearchStationMultiselect"

const Validator = SimpleVueValidation.Validator

export default {
  name: 'StationEdit',
  props: ['stationIn'],
  data () {
    return {
      station: null,
      showAlert: false,
      isNewAlert: false,
      alertToUpdate: null,
      currentAlertId: null,
      daysConstant: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'ferie'],
      daysAgentsError: false,
      daysAgentsErrors: {
        monday: null, tuesday: null, wednesday: null, thursday: null, friday: null, saturday: null, sunday: null, ferie: null
      },
      daysTaxi: {
        mondayTaxi: { start: null, end: null },
        tuesdayTaxi: { start: null, end: null },
        wednesdayTaxi: { start: null, end: null },
        thursdayTaxi: { start: null, end: null },
        fridayTaxi: { start: null, end: null },
        saturdayTaxi: { start: null, end: null },
        sundayTaxi: { start: null, end: null },
        ferieTaxi: { start: null, end: null }
      },
      services: [],
      gcActors: [],
      transporters: [],
      hasOneDayAgentFilled: false,
      STATION_FULL_ACCESSIBILITY,
      STATION_PARTIAL_ACCESSIBILITY,
      STATION_LIMITED_ACCESSIBILITY,
      STATION_NO_ACCESSIBILITY,
      modalAlertConfirmation: false,
      modalActorConfirmation: false,
      selectedActor: null,
      isLoading: false
    }
  },
  created () {
    this.station = Object.assign({}, this.stationIn)
    this.services = this.$sncfRefs.getServicesModelsForUpsert()
    this.getGcActors()
    this.transporters = this.$sncfRefs.getTransportersModelsForFilters()

    if (this.station === null) {
      this.getStation()
    } else {
      this.checkAuthorization()
    }

    this.formatHours(this.daysTaxi)

    this.station.assistActors.forEach(aa => {
      this.formatAssistActorsHours(aa)
    })

    if (this.station.isPMR) {
      this.checkIfAgentsHoursAreFilled()
    }
  },
  mounted () {
    $(this.$el).foundation()
  },
  updated() {
    $(this.$el).foundation()
  },
  methods: {
    checkIfAgentsHoursAreFilled: function () {
      this.daysAgentsError = false
      this.hasOneDayAgentFilled = false
      for (let i = 0; i < this.daysConstant.length; i++) {
        for (const assistActor of this.station.assistActors) {
          const dayConst = this.daysConstant[i]
          let errorsForDay = []
          let closed = assistActor[dayConst + 'AgentClosed']
          if (!closed) {
            let hourPeriod = [assistActor.daysAgent[dayConst + 'Agent'], assistActor.daysAgent[dayConst + 'Agent2'], assistActor.daysAgent[dayConst + 'Agent3']]
            if (dayConst !== 'ferie') {
              hourPeriod.map(field => {
                if (field.start && field.end) { this.hasOneDayAgentFilled = true }
              })
            }
            if (hourPeriod.some(field => { return ((field.start && !field.end) || (!field.start && field.end)) })) {
              errorsForDay.push(this.$t('stations.hoursMissing'))
            } else {
              let hours = []
              if (assistActor.daysAgent[dayConst + 'Agent'].start && assistActor.daysAgent[dayConst + 'Agent'].end) {
                hours.push(assistActor.daysAgent[dayConst + 'Agent'].start)
                hours.push(assistActor.daysAgent[dayConst + 'Agent'].end)
              }
              if (assistActor.daysAgent[dayConst + 'Agent2'].start && assistActor.daysAgent[dayConst + 'Agent2'].end) {
                hours.push(assistActor.daysAgent[dayConst + 'Agent2'].start)
                hours.push(assistActor.daysAgent[dayConst + 'Agent2'].end)
              }
              if (assistActor.daysAgent[dayConst + 'Agent3'].start && assistActor.daysAgent[dayConst + 'Agent3'].end) {
                hours.push(assistActor.daysAgent[dayConst + 'Agent3'].start)
                hours.push(assistActor.daysAgent[dayConst + 'Agent3'].end)
              }
              if (hours.length === 0) {
                errorsForDay.push(this.$t('stations.hoursMissing'))
                this.$toastService.showToast(this.$t('stations.hourError'), null, 'error')
              } else {
                for (let j = 0; j < (hours.length - 2); j++) { // we don't check last hour, checked after
                  let first = hours[j]
                  let next = hours[j + 1]
                  if (first && next &&
                      first >= next) {
                    errorsForDay.push(this.$t('stations.hoursInconsistency'))
                    this.$toastService.showToast(this.$t('stations.hoursInconsistency'), null, 'error')
                  }
                }
                // last hour can be inferior to pre last hour only if also inferior to first one
                let firstHour = hours[0]
                let lastHour = hours[hours.length - 1]
                let preLastHour = hours[hours.length - 2]
                if (lastHour <= preLastHour && lastHour >= firstHour) {
                  errorsForDay.push(this.$t('stations.hoursInconsistency'))
                  this.$toastService.showToast(this.$t('stations.hoursInconsistency'), null, 'error')
                }
              }
            }
          }

          if (errorsForDay.length > 0) {
            this.daysAgentsErrors[dayConst] = errorsForDay.join(' ')
            this.daysAgentsError = true
          } else {
            this.daysAgentsErrors[dayConst] = null
          }
        }
      }
    },
    reformatHours: function(daysAgent, object) {
      const days = Object.keys(daysAgent)
      for (let i in days) {
        const dayName = days[i]
        const day = daysAgent[dayName]
        if (day.start && day.end) {
          object[dayName] = day.start + '-' + day.end
        } else {
          object[dayName] = null
        }
      }
    },
    saveStation: function () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      const codeUic = this.$route.params.codeUic
      this.reformatHours(this.daysTaxi, this.station)
      this.station.assistActors.forEach(assistActor => {
        this.reformatHours(assistActor.daysAgent, assistActor)
      })
      this.station.updateDate = new Date()
      this.station.updateAccount = this.$store.getters.userFullName
      if (this.station.isPMR) {
        this.checkIfAgentsHoursAreFilled()
      } else {
        this.daysAgentsError = false
      }
      this.checkIncumbents()

      const self = this
      this.$validate()
        .then(function (success) {
          if (success && self.isAssistActorsErrors && !self.checkDuplicateAssistActors()) {
            return self.$backendConnector.updateStation(codeUic, { station: self.station })
              .then(res => {
                self.$toastService.showToast(self.$t('stations.updateOk'), null, 'success')
                self.$emit('updateIsComplete')
              })
              .catch(error => {
                console.log(error, 'Something went wrong')
                self.isLoading = false
              })
          } else {
            self.isLoading = false
          }
        }).catch(err => {
          console.log(err, 'Something went wrong')
        })
    },
    getStation: function () {
      return this.$backendConnector.getGare(this.$route.params.herokuExternalId)
        .then(res => {
          this.station = res
          return this.station
        })
        .then(station => {
          this.checkAuthorization()
        })
        .catch(error => console.log(error, 'Something went wrong'))
    },
    formatHours: function (days) {
      const dayList = Object.keys(days)
      dayList.map(d => {
        if (this.station[d]) {
          const h = this.station[d].split('-')
          days[d].start = h[0]
          days[d].end = h[1]
        }
      })
    },
    formatAssistActorsHours: function (assistActor) {
      const dayList = Object.keys(assistActor.daysAgent)
      dayList.map(d => {
        if (assistActor[d]) {
          const h = assistActor[d].split('-')
          assistActor.daysAgent[d].start = h[0]
          assistActor.daysAgent[d].end = h[1]
        }
      })
    },
    checkAuthorization: function () {
      let canEdit = this.$perms.isUserAllowed('stations.update')
      let canEditAll = this.$perms.isUserAllowed('stations.update.all')
      let canEditSelf = this.$perms.isUserAllowed('stations.update.self')
      let isAllowedToEditThisStation = canEdit && (
        canEditAll ||
        (canEditSelf &&
            (
              this.$store.getters.possibleStations.map(s => s.codeUic).includes(this.station.codeUic) ||
              (this.$perms.isUserAllowed('stations.agencies') && this.$store.getters.userAgenciesCode.includes(this.station.agencyCode)) ||
              (this.$perms.isUserAllowed('stations.regions') && this.$store.getters.userRegionCodes.includes(this.station.region))
            )
        )
      )
      if (!isAllowedToEditThisStation) {
        this.$router.push({ name: 'station', params: { herokuExternalId: this.$route.params.herokuExternalId } })
      }
    },
    canReadField: function (field) {
      return this.$perms.canUserRead('stations.update', field)
    },
    canEditField: function (field) {
      return this.$perms.canUserEdit('stations.update', field)
    },
    openModalConfirmDeletion: function(actor) {
      this.selectedActor = actor
      this.modalActorConfirmation = true
    },
    closeModalActor: function() {
      this.selectedActor = null
      this.modalActorConfirmation = false
    },
    deleteAssistActor: function () {
      const index = this.station.assistActors.findIndex(assistActor => assistActor.gcActorCode === this.selectedActor.gcActorCode &&
                                                                       assistActor.transporterCode === this.selectedActor.transporterCode &&
                                                                       assistActor.stationUic === this.selectedActor.stationUic)
      this.station.assistActors.splice(index, 1)
      this.selectedActor = null
      this.modalActorConfirmation = false
    },
    handleAgentDayClosedCheckboxChange(dayConst, assistActor) {
      if (assistActor[dayConst + 'AgentClosed']) {
        assistActor.daysAgent[dayConst + 'Agent'].start = null;
        assistActor.daysAgent[dayConst + 'Agent'].end = null;
        assistActor.daysAgent[dayConst + 'Agent2'].start = null;
        assistActor.daysAgent[dayConst + 'Agent2'].end = null;
        assistActor.daysAgent[dayConst + 'Agent3'].start = null;
        assistActor.daysAgent[dayConst + 'Agent3'].end = null;
      }
    },
    addAssistActor: function() {
      this.station.assistActors.push({
        gcActorCode: null,
        transporterCode: null,
        respGc: true,
        gcActor: null,
        transporter: null,
        assistMail: null,
        assistName: null,
        createddate: null,
        daysAgent: {
          mondayAgent: { start: null, end: null },
          tuesdayAgent: { start: null, end: null },
          wednesdayAgent: { start: null, end: null },
          thursdayAgent: { start: null, end: null },
          fridayAgent: { start: null, end: null },
          saturdayAgent: { start: null, end: null },
          sundayAgent: { start: null, end: null },
          ferieAgent: { start: null, end: null },
          mondayAgent2: { start: null, end: null },
          tuesdayAgent2: { start: null, end: null },
          wednesdayAgent2: { start: null, end: null },
          thursdayAgent2: { start: null, end: null },
          fridayAgent2: { start: null, end: null },
          saturdayAgent2: { start: null, end: null },
          sundayAgent2: { start: null, end: null },
          ferieAgent2: { start: null, end: null },
          mondayAgent3: { start: null, end: null },
          tuesdayAgent3: { start: null, end: null },
          wednesdayAgent3: { start: null, end: null },
          thursdayAgent3: { start: null, end: null },
          fridayAgent3: { start: null, end: null },
          saturdayAgent3: { start: null, end: null },
          sundayAgent3: { start: null, end: null },
          ferieAgent3: { start: null, end: null }
        },
        emailAssist: null,
        external1: null,
        external2: null,
        ferieAgent: null,
        ferieAgent2: null,
        ferieAgent3: null,
        ferieAgentClosed: false,
        fridayAgent: null,
        fridayAgent2: null,
        fridayAgent3: null,
        fridayAgentClosed: false,
        handicapAudio: false,
        handicapAutre: false,
        handicapMental: false,
        handicapMoteur: false,
        handicapVisuel: false,
        hcErr: null,
        hcLastOp: null,
        internal1: null,
        internal2: null,
        leadMail: null,
        leadName: null,
        mobile1: null,
        mobile2: null,
        mondayAgent: null,
        mondayAgent2: null,
        mondayAgent3: null,
        mondayAgentClosed: false,
        name: null,
        saturdayAgent: null,
        saturdayAgent2: null,
        saturdayAgent3: null,
        saturdayAgentClosed: false,
        sfid: null,
        stationUic: this.station.codeUic,
        sundayAgent: null,
        sundayAgent2: null,
        sundayAgent3: null,
        sundayAgentClosed: false,
        thursdayAgent: null,
        thursdayAgent2: null,
        thursdayAgent3: null,
        thursdayAgentClosed: false,
        tuesdayAgent: null,
        tuesdayAgent2: null,
        tuesdayAgent3: null,
        tuesdayAgentClosed: false,
        wednesdayAgent: null,
        wednesdayAgent2: null,
        wednesdayAgent3: null,
        wednesdayAgentClosed: false,
        assistanceTrain: null,
        hasError: {
          leadMail: false,
          assistMail: false,
          emailAssist: false,
          acteur: false,
        },
        localId: Math.floor(Math.random() * 100000000)
      })
    },
    getGcActors: function() {
      this.$backendConnector.getGcActors()
        .then(gcActors => {
          this.gcActors = gcActors
        })
    },
    updateActor: function(code, assistActor) {
      const gcActor = this.gcActors.find(a => a.code === code)
      assistActor.gcActor = gcActor
    },
    updateTransporter: function(code, assistActor) {
      const transporter = this.transporters.find(a => a.code === code)
      assistActor.transporter = transporter
    },
    checkIncumbents: function() {
      this.station.assistActors.forEach(aa => {
        aa.hasError.acteur = aa.gcActorCode === null && aa.transporterCode === null
      })
    },
    onResponsibleInput: function(respGc, assistActor) {
      assistActor.respGc = respGc
      respGc ? assistActor.transporterCode = null : assistActor.gcActorCode = null
      assistActor.assistanceTrain = respGc ? assistActor.assistanceTrain : null
    },
    checkDuplicateAssistActors: function () {
      const values = [
        ...this.station.assistActors.map(aa => aa.gcActorCode),
        ...this.station.assistActors.map(aa => aa.transporterCode)
      ].filter(value => value !== null)

      const isDuplicates = new Set(values).size !== values.length

      if (isDuplicates) this.$toastService.showToast(this.$t('station.duplicateAssistActor'), null, 'error')

      return isDuplicates
    },
    addAlert: function () {
      this.isNewAlert = true
      this.showAlert = true
    },
    closeAlert: function() {
      this.isNewAlert = true
      this.showAlert = false
      this.alertToUpdate = null
    },
    updateAlert: function(val) {
      this.isNewAlert = false
      this.alertToUpdate = this.station.alerts.filter(alert => alert.id === val)[0]
      this.showAlert = true
    },
    deleteAlert: function() {
      this.$backendConnector.deleteAlert(this.currentAlertId)
        .then(res => {
          this.$toastService.showToast(this.$t('stations.updateOk'), null, 'success')
          this.modalAlertConfirmation = false
          this.station.alerts = this.station.alerts.filter(alert => alert.id !== this.currentAlertId)
          this.currentAlertId = null
        })
        .catch(error => console.log(error, 'Something went wrong'))
    },
    openDeleteConfirmation: function(val) {
      this.currentAlertId = val
      this.modalAlertConfirmation = true
    },
    closeDeleteConfirmation: function() {
      this.currentAlertId = null
      this.modalAlertConfirmation = false
    },
    alertUpserted: function(alert) {
      const alertIndex = this.station.alerts.findIndex(a => a.id === alert.id)
      if (alertIndex > -1) {
        this.station.alerts.splice(alertIndex, 1, alert)
      } else {
        this.station.alerts.push(alert)
      }
    },
    async selectBackup(valSelected, backup) {
      try {
        switch (backup) {
          case 1:
            this.station.backupStation1 = valSelected?.codeUic || null
            break;
          case 2:
            this.station.backupStation2 = valSelected?.codeUic || null
            break;
          case 3:
            this.station.backupStation3 = valSelected?.codeUic || null
            break;
          default:
            break;
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  computed: {
    isAssistActorsErrors: function() {
      return !this.station.assistActors.some(aa => Object.values(aa.hasError).some(error => error))
    },
    isAllowedToAddOrDeleteActorAssist() {
      return (
        userService.isAdministrator() ||
        userService.isAdminTranspo() ||
        userService.isReferent() ||
        userService.isRefTranspo()
      );
    },
    canModifyAccessibility() {
      return userService.isAdminNat() || userService.isDT()
    },
    shouldEditBasicInfos() {
      return this.$perms.isUserAllowed('stations.update.shouldEditBasicInfos')
    },
    shouldEditHours() {
      return this.$perms.isUserAllowed('stations.update.shouldEditHours')
    },
    shouldEditRegion() {
      return this.$perms.isUserAllowed('stations.update.shouldEditRegion')
    }
  },
  validators: {
    'station.accessibilityLevel': function (value) {
      if (this.shouldEditBasicInfos && this.canModifyAccessibility) {
        return Validator.value(value).required()
      }
    },
    'station.type': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.isPMR': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.boardingAid': function (value) {
      if (this.shouldEditHours) {
        return Validator.value(value).required()
      }
    },
    'station.garanteed': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.spontaneous': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.soca': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.region': function (value) {
      if (this.shouldEditRegion) {
        return Validator.value(value).required()
      }
    },
    'station.agency': function (value) {
      if (this.shouldEditBasicInfos) {
        return Validator.value(value).required()
      }
    },
    'station.assistActors': function (assistActors) {
      for (const assistActor of assistActors) {
        assistActor.hasError.leadMail = Validator.value(assistActor.leadMail).email()._messages.length > 0
        assistActor.hasError.assistMail = Validator.value(assistActor.assistMail).email()._messages.length > 0
        assistActor.hasError.emailAssist = Validator.value(assistActor.emailAssist).email()._messages.length > 0
      }
    },
    'hasOneDayAgentFilled': function (value) {
      if (this.shouldEditHours && this.station.isPMR && !this.hasOneDayAgentFilled) {
        this.$toastService.showToast(this.$t('stations.hourError'), null, 'error')
        return Validator.value(null).required()
      }
    },
    'daysAgentsError': function (value) {
      if (this.daysAgentsError === true) {
        return Validator.value(null).required()
      }
    }
  },
  components: {
    Timepicker, Multiselect, UpdateAlert, Alert, SearchStationMultiselect
  }
}
</script>
