import i18n from '@/plugins/vue-i18n'

const baseLang = 'fr'

const prestationConverter = {
  convertToFront: function (prestation) {
    if (typeof prestation === 'undefined') {
      return prestation
    }
    prestation.statusTN = prestationConverter.getStatusFromLabel(prestation.status)
    if (prestation.Parcour && prestation.Parcour.Prestations) {
      for (let i in prestation.Parcour.Prestations) {
        prestation.Parcour.Prestations[i] = prestationConverter.convertToFront(prestation.Parcour.Prestations[i])
      }
    }
    prestation.trainDeparture = prestation.datePrestation
    return prestation
  },
  convertFromFront: function (prestation) {
    if (typeof prestation === 'undefined') {
      return prestation
    }
    if (prestation.statusTN) {
      prestation.status = prestationConverter.getLabelFromStatus(prestation.statusTN)
    }
    return prestation
  },
  getStatusFromLabel: function (statusLabelToConvert) {
    let statuses = i18n.t('prestation.status', baseLang)
    for (let statusCode in statuses) {
      let statusLabel = statuses[statusCode]
      if (statusLabel === statusLabelToConvert) {
        return statusCode
      }
    }
    return ''
  },
  getLabelFromStatus: function (statusTnToConvert) {
    return i18n.t('prestation.status.' + statusTnToConvert, baseLang)
  }
}

export default prestationConverter
