<template>
  <div class="adminPage">
    <div class="closePreviewLayer" v-if="openPreview" @click="closePreview">
    </div>
      <div v-if="showFilters" v-bind:class="{ 'hidden': !showFilters }">
        <UserFilters @close="close" @filteredUsers="filteredUsers" />
      </div>
      <div class="adminHeader">
        <div class="pageTitle">
          <div class="socaContent adminContent">
            <div class="headerTitle">
              {{$t('user.list.title') }}
            </div>
            <router-link class="addUserButton button-theme-round-light hide-for-small-only hide-for-medium-only" to="/user/new">{{ $t('common.create') }}</router-link>
            <button class="filterButton" v-bind:class="{ 'isFiltered': isFiltered }" @click="filterAction">
              <svgicon icon="filter"/>
              <span>{{ $t('common.filter') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="socaContent adminContent" v-bind:class="{ withPreview: previewedUser }">
        <div class="grid-x grid-margin-x" v-if="canExportInCsv">
          <div class="cell small-12 downloadCSV">
            <button @click="downloadAsCsv">
                  <svgicon icon="download" class="download-icon" />
                  <span class="button-text">{{$t('common.download.csv')}}</span>
            </button>
          </div>
        </div>
        <div class="formElement">
          <input class="adminSearchInput" type="text" v-model="filterQuery" v-bind:placeholder="$t('user.list.searchLabel')" v-debounce="filterQueryAction">
        </div>
        <simplebar id="userItems"
          v-on:scrollEnd="mainContentScrollEnd"
          :backToTop="true"
          data-simplebar-auto-hide="false">
            <UserListItems :users="users" @userSelected="selectUserAction" @fetchUsers="fetchUsers"/>
            <svgicon class="loadingAnimation" icon="loader" v-if="isLoading" />
        </simplebar>
        <div class="adminPageAddItem hide-for-large" v-if='!previewedUser'>
          <router-link to="/user/new">+</router-link>
        </div>
      </div>
    <UserPreview v-if="openPreview" :user="previewedUser" @closePreview="closePreview" @refresh="removeUserFromList"/>
  </div>
</template>

<script>
import userService from '@/services/userService'
import UserFilters from '@/components/Users/Filters'
import UserListItems from '@/components/Users/ListItems'
import UserPreview from '@/components/Users/Preview'
import commonService from '@/services/common'

export default {
  name: 'UserList',
  components: { UserFilters, UserListItems, UserPreview },
  data () {
    return {
      userSearch: true,
      users: null,
      isFiltered: false,
      showFilters: false,
      filterQuery: null,
      previewedUser: null,
      isLoading: false,
      openPreview: false,
      searchUserCallId: 0,
      pageSize: 50,
      page: 0,
      lastUserCount: 0,
      hasAllUsers: false
    }
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    getFilters () {
      let self = this
      let filters = {}
      self.isLoading = true
      Object.assign(filters, this.$store.getters['userFilters/filters'])
      this.isFiltered = Object.values(filters).some(x => (x !== null))

      this.filterQuery = this.$store.getters['userFilters/searchQuery']
      filters.query = this.filterQuery
      filters.stations = filters.stations ? filters.stations.map(function (station) { return station.codeUic }) : null
      return filters
    },
    fetchUsers () {
      let self = this
      let filters = this.getFilters()
      this.searchUserCallId++
      let currentSearchUserCallId = this.searchUserCallId
      userService.getUsers(filters, { pagesize: this.pageSize, page: this.page }).then(userList => {
        if (currentSearchUserCallId === this.searchUserCallId) {
          if (self.users === null) {
            self.users = userList
          } else {
            self.users.rows = self.users.rows.concat(userList.rows)
            self.users.count += userList.count
            if (self.lastUserCount === self.users.count) {
              self.hasAllUsers = true
            }
            self.lastUserCount = self.users.count
          }
          self.isLoading = false
          self.previewedUser = null
        }
      })
      this.previewedUser = null
    },
    removeUserFromList (userCode) {
      const userToRemoveIndex = this.users.rows.findIndex(user => user.code === userCode)
      this.users.rows.splice(userToRemoveIndex, 1)
      this.users.count = this.users.count - 1
    },
    mainContentScrollEnd () {
      if (!this.users && !this.isLoading && !this.hasAllUsers) {
        this.page += 1
        this.fetchUsers()
      }
    },
    filteredUsers () {
      this.hasAllUsers = false
      this.users = null
      this.page = 0
      this.fetchUsers()
      this.showFilters = false
    },
    filterAction () {
      this.showFilters = !this.showFilters
    },
    filterQueryAction () {
      this.previewedUser = null
      this.$store.commit('userFilters/setSearchQuery', this.filterQuery)
      this.filteredUsers()
    },
    selectUserAction (userSelected) {
      this.previewedUser = userSelected
      this.openPreview = true
    },
    close () {
      this.showFilters = false
    },
    closePreview () {
      if (this.openPreview) {
        this.openPreview = false
        this.previewedUser = null
      }
    },
    downloadAsCsv () {
      let filters = this.getFilters()
      return this.$backendConnector.getUsersCsv(filters)
        .then(result => {
          commonService.downloadBlob(result.data, 'export.csv')
          this.isLoading = false
        })
        .catch(err => console.log(err))
    }
  },
  computed: {
    canExportInCsv: function () {
      return this.$perms.isUserAllowed('users.export')
    }
  }
}
</script>
