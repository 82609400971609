<template>
  <socamodal
    class="modalAlert"
    :title="$t('station.alert.creationAlert')"
    @close="closeAlert"
    @submit="submit">
    <template v-slot:header>
      <div class="modalHeaderInfo modalMandatoryInfo hide-for-small-only">{{$t('common.field.mandatoryFields')}}</div>
    </template>
      <div class="formElement cell small-12 large-12" >
        <label>{{$t('station.alert.name') }}* <small v-if="validation.hasError('currentAlert.name')" class="error">{{ validation.firstError('currentAlert.name') }}</small></label>
        <input type="text"
          :placeholder="`${$t('station.alert.namePlaceholder') }`"
          v-model="currentAlert.name"
          maxlength="80" />
        <InputRemainingCharacters v-if="currentAlert.name" :maxCharacters="80" :currentLength="currentAlert.name.length"></InputRemainingCharacters>
      </div>
      <div class="formElement cell small-12 large-12" >
        <label>{{$t('station.alert.description') }}* <small v-if="validation.hasError('currentAlert.description')" class="error">{{ validation.firstError('currentAlert.description') }}</small></label>
        <textarea rows="5" cols="33"
          :placeholder="`${$t('station.alert.descriptionPlaceholder') }`"
          v-model="currentAlert.description"
          maxlength="32000"></textarea>
        <InputRemainingCharacters v-if="currentAlert.description" :maxCharacters="32000" :currentLength="currentAlert.description.length"></InputRemainingCharacters>
      </div>
      <h3 class="importantTitle">{{$t('station.alert.start') }}</h3>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('common.date') }}* <small v-if="validation.hasError('currentAlert.startAlert.date')" class="error">{{ validation.firstError('currentAlert.startAlert.date') }}</small></label>
          <Datepicker v-model="currentAlert.startAlert.date"></Datepicker>
        </div>
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('common.hour') }}* <small v-if="validation.hasError('currentAlert.startAlert.hour')" class="error">{{ validation.firstError('currentAlert.startAlert.hour') }}</small></label>
          <Timepicker v-model="currentAlert.startAlert.hour"></Timepicker>
        </div>
      </div>
      <h3 class="importantTitle">{{$t('station.alert.end') }}</h3>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('common.date') }}* <small v-if="validation.hasError('currentAlert.endOfAlert.date')" class="error">{{ validation.firstError('currentAlert.endOfAlert.date') }}</small></label>
          <Datepicker v-model="currentAlert.endOfAlert.date"></Datepicker>
        </div>
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('common.hour') }}* <small v-if="validation.hasError('currentAlert.endOfAlert.hour')" class="error">{{ validation.firstError('currentAlert.endOfAlert.hour') }}</small></label>
          <Timepicker v-model="currentAlert.endOfAlert.hour"></Timepicker>
        </div>
      </div>
      <small class="error" v-if="intervalDateError">{{ $t("station.alert.dateConsistancyErrorMessage") }}</small>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('station.alert.initiator') }}* <small v-if="validation.hasError('currentAlert.initiator')" class="error">{{ validation.firstError('currentAlert.initiator') }}</small></label>
          <input type="text"
            v-model="currentAlert.initiator"
            maxlength="100" />
          <InputRemainingCharacters v-if="currentAlert.initiator" :maxCharacters="100" :currentLength="currentAlert.initiator.length"></InputRemainingCharacters>
        </div>
        <div class="formElement cell small-12 large-6">
          <label>{{ $t('station.alert.type') }}*  <small v-if="validation.hasError('currentAlert.type')" class="error">{{ validation.firstError('currentAlert.type') }}</small></label>
          <selectpl v-model="currentAlert.type">
            <option v-for="alertType in $sncfRefs.getAlertTypes()" :value="alertType" :key="alertType">{{ $t('station.alertTypeList.'+alertType) }}</option>
          </selectpl>
        </div>
      </div>
      <h3 class="importantTitle">{{$t('station.alert.handicapImpacted') }}</h3>
      <div class="columnsCheckboxes">
        <div class="columnCheckboxes">
          <label class="checkContainer">{{$t('station.alert.auditiveHandicap') }}
            <input type="checkbox" v-model="currentAlert.isAuditive">
            <span class="checkmark"></span>
          </label>
          <label class="checkContainer">{{$t('station.alert.mentalHandicap') }}
            <input type="checkbox" v-model="currentAlert.isMental">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="columnCheckboxes">
        <label class="checkContainer">{{$t('station.alert.motorHandicap') }}
          <input type="checkbox" v-model="currentAlert.isMotor">
          <span class="checkmark"></span>
        </label>
        <label class="checkContainer">{{$t('station.alert.visualHandicap') }}
          <input type="checkbox" v-model="currentAlert.isVisual">
          <span class="checkmark"></span>
        </label>
        </div>
        <div class="columnCheckboxes">
        <label class="checkContainer">{{$t('station.alert.otherHandicap') }}
          <input type="checkbox" v-model="currentAlert.isOtherPMR">
          <span class="checkmark"></span>
        </label>
        </div>
      </div>
      <div class="formElement cell small-12 large-12" >
        <label>{{$t('station.alert.solution') }}* <small v-if="validation.hasError('currentAlert.solution')" class="error">{{ validation.firstError('currentAlert.solution') }}</small></label>
        <textarea rows="5" cols="33"
          :placeholder="`${$t('station.alert.solutionPlaceholder') }`"
          v-model="currentAlert.solution"
          maxlength="2000"></textarea>
        <InputRemainingCharacters v-if="currentAlert.solution" :maxCharacters="2000" :currentLength="currentAlert.solution.length"></InputRemainingCharacters>
      </div>
      <div class="formElement oneliner cell small-12 large-12">
        <label class="titleRadio">{{ $t('station.alert.isNewPickupLocation') }}* <small v-if="validation.hasError('currentAlert.isNewPickupLocation')" class="error">{{ validation.firstError('station.alert.isNewPickupLocation') }}</small></label>
        <div class="formRadio">
          <label><input type="radio" id="true" v-bind:value="true" v-model="currentAlert.isNewPickupLocation"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" id="false" v-bind:value="false" v-model="currentAlert.isNewPickupLocation"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement cell small-12 large-12" v-if="currentAlert.isNewPickupLocation">
        <label>{{$t('station.alert.newPickupLocation') }}* <small v-if="validation.hasError('currentAlert.newPickupLocation')" class="error">{{ validation.firstError('currentAlert.newPickupLocation') }}</small></label>
        <input type="text"
          :placeholder="`${$t('station.alert.newPickupLocationPlaceholder') }`"
          v-model="currentAlert.newPickupLocation"
          maxlength="255" />
        <InputRemainingCharacters v-if="currentAlert.newPickupLocation" :maxCharacters="255" :currentLength="currentAlert.newPickupLocation.length"></InputRemainingCharacters>
      </div>
      <div class="formElement oneliner cell small-12 large-12">
        <label class="titleRadio">{{ $t('station.alert.isReplacementBus') }}* <small v-if="validation.hasError('currentAlert.isReplacementBus')" class="error">{{ validation.firstError('station.alert.isReplacementBus') }}</small></label>
        <div class="formRadio">
          <label><input type="radio" id="true" v-bind:value="true" v-model="currentAlert.isReplacementBus"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" id="false" v-bind:value="false" v-model="currentAlert.isReplacementBus"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement oneliner cell small-12 large-12" v-if="currentAlert.isReplacementBus">
        <label class="titleRadio">{{ $t('station.alert.isReplacementBusWheelchairAccessible') }} <small v-if="validation.hasError('currentAlert.isReplacementBusWheelchairAccessible')" class="error">{{ validation.firstError('station.alert.isReplacementBusWheelchairAccessible') }}</small></label>
        <div class="formRadio">
          <label><input type="radio" id="true" v-bind:value="true" v-model="currentAlert.isReplacementBusWheelchairAccessible"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" id="false" v-bind:value="false" v-model="currentAlert.isReplacementBusWheelchairAccessible"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement oneliner cell small-12 large-12">
        <label class="titleRadio">{{ $t('station.alert.isTaxiSubstitution') }} <small v-if="validation.hasError('currentAlert.isTaxiSubstitution')" class="error">{{ validation.firstError('station.alert.isTaxiSubstitution') }}</small></label>
        <div class="formRadio">
          <label><input type="radio" id="true" v-bind:value="true" v-model="currentAlert.isTaxiSubstitution"><span>{{$t('common.yes') }}</span></label>
          <label><input type="radio" id="false" v-bind:value="false" v-model="currentAlert.isTaxiSubstitution"><span>{{$t('common.no') }}</span></label>
        </div>
      </div>
      <div class="formElement cell small-12 large-12" >
        <label>{{$t('station.alert.contactName') }}* <small v-if="validation.hasError('currentAlert.contactName')" class="error">{{ validation.firstError('currentAlert.contactName') }}</small></label>
        <input type="text"
          :placeholder="`${$t('station.alert.contactNamePlaceholder') }`"
          v-model="currentAlert.contactName"
          maxlength="500" />
        <InputRemainingCharacters v-if="currentAlert.contactName" :maxCharacters="500" :currentLength="currentAlert.contactName.length"></InputRemainingCharacters>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('station.alert.contactEmail') }}* <small v-if="validation.hasError('currentAlert.contactEmail')" class="error">{{ $t('error.field.incorrectFormat') }}</small></label>
          <input type="text"
            :placeholder="`${$t('station.alert.contactEmailPlaceholder') }`"
            v-model="currentAlert.contactEmail"
            maxlength="80" />
          <InputRemainingCharacters v-if="currentAlert.contactEmail" :maxCharacters="80" :currentLength="currentAlert.contactEmail.length"></InputRemainingCharacters>
        </div>
        <div class="formElement cell small-12 large-6" >
          <label>{{$t('station.alert.contactPhone') }}* <small v-if="validation.hasError('currentAlert.contactPhone')" class="error">{{ validation.firstError('currentAlert.contactPhone') }}</small></label>
          <input type="text"
            :placeholder="`${$t('station.alert.contactPhonePlaceholder') }`"
            v-model="currentAlert.contactPhone"
            maxlength="40" />
          <InputRemainingCharacters v-if="currentAlert.contactPhone" :maxCharacters="40" :currentLength="currentAlert.contactPhone.length"></InputRemainingCharacters>
        </div>
      </div>
      <div class="formElement cell small-12 large-12" v-if="currentAlert.isReplacementBus || currentAlert.isTaxiSubstitution">
        <label>{{$t('station.alert.alternedJourneyTime') }}* <small v-if="validation.hasError('currentAlert.alternedJourneyTime')" class="error">{{ validation.firstError('currentAlert.alternedJourneyTime') }}</small></label>
        <input type="text"
          :placeholder="`${$t('station.alert.alternedJourneyTimePlaceholder') }`"
          v-model="currentAlert.alternedJourneyTime"
          maxlength="200" />
        <InputRemainingCharacters v-if="currentAlert.alternedJourneyTime" :maxCharacters="200" :currentLength="currentAlert.alternedJourneyTime.length"></InputRemainingCharacters>
      </div>
      <div class="formElement cell small-12 large-12" v-if="currentAlert.isReplacementBus && !currentAlert.isReplacementBusWheelchairAccessible || currentAlert.isTaxiSubstitution">
        <label>{{$t('station.alert.alteredJourneyStation') }} <small v-if="validation.hasError('currentAlert.alteredJourneyStation')" class="error">{{ validation.firstError('currentAlert.alteredJourneyStation') }}</small></label>
        <input type="text"
          :placeholder="`${$t('station.alert.alteredJourneyStationPlaceholder') }`"
          v-model="currentAlert.alteredJourneyStation"
          maxlength="500" />
        <InputRemainingCharacters v-if="currentAlert.alteredJourneyStation" :maxCharacters="500" :currentLength="currentAlert.alteredJourneyStation.length"></InputRemainingCharacters>
      </div>
  </socamodal>
</template>
<script>
import Datepicker from '@/components/Common/Datepicker'
import Timepicker from '@/components/Common/Timepicker'
import SimpleVueValidation from 'simple-vue-validator'
import common from '@/services/common'
import moment from 'moment'
import InputRemainingCharacters from '../Common/InputRemainingCharacters.vue'

const Validator = SimpleVueValidation.Validator

export default {
  name: "UpdateAlert",
  data() {
    return {
      currentAlert: null,
      intervalDateError: false
    }
  },
  props: {
    alertData: Object,
    isNewAlert: Boolean,
    codeUic: String,
  },
  created() {
    if (this.isNewAlert) {
      this.addAlert()
    } else {
      this.currentAlert = this.formatReceivedAlert(this.alertData)
    }
  },
  watch: {
    'currentAlert.startAlert': {
      deep: true,
      handler () {
        this.controlDates()
      }
    },
    'currentAlert.endOfAlert': {
      deep: true,
      handler () {
        this.controlDates()
      }
    }
  },
  methods: {
    closeAlert () {
      this.$emit('closeAlert')
    },
    upsertAlert (alert) {
      this.$emit('alertUpserted', alert)
    },
    submit: async function() {
      const success = await this.$validate()
      this.controlDates()
      if (success && !this.intervalDateError) {
        const formattedAlert = this.formatAlertToSend(this.currentAlert)
        try {
          const result = await this.$backendConnector.upsertAlert({ alert: formattedAlert, codeUic: this.codeUic })
          if (result) {
            if (Array.isArray(result)) { // un update
              this.upsertAlert(result[1])
            } else { // un create
              this.upsertAlert(result)
            }
            this.$toastService.showToast(this.$t('stations.updateOk'), null, 'success')
          }
        } catch (err) {
          console.error("something went wrong with the alert")
        }
        this.closeAlert()
      } else {
        console.error("something went wrong with the alert")
      }
    },
    addAlert: function() {
      this.currentAlert = {
        name: null,
        description: null,
        startAlert: {
          date: null,
          hour: null
        },
        endOfAlert: {
          date: null,
          hour: null
        },
        initiator: this.$store.getters.userFullName,
        type: null,
        isAuditive: false,
        isMental: false,
        isMotor: false,
        isVisual: false,
        isOtherPMR: false,
        solution: null,
        isNewPickupLocation: false,
        newPickupLocation: null,
        isReplacementBus: false,
        isReplacementBusWheelchairAccessible: false,
        isTaxiSubstitution: false,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        alternedJourneyTime: null,
        alteredJourneyStation: null
      }
    },
    formatReceivedAlert: function(alert) {
      const result = {
        ...alert,
        startAlert: {
          date: new Date(alert.startAlert),
          hour: new Date(alert.startAlert).getHours() + ':' + new Date(alert.startAlert).getMinutes()
        },
        endOfAlert: {
          date: new Date(alert.endOfAlert),
          hour: new Date(alert.endOfAlert).getHours() + ':' + new Date(alert.endOfAlert).getMinutes()
        }
      }
      return result
    },
    formatAlertToSend: function(alert) {
      const result = {
        ...alert,
        startAlert: common.setHoursMinute(alert.startAlert.hour, alert.startAlert.date),
        endOfAlert: common.setHoursMinute(alert.endOfAlert.hour, alert.endOfAlert.date)
      }
      return result
    },
    controlDates () {
      if (this.currentAlert.startAlert.date && this.currentAlert.endOfAlert.date) {
        let start = moment(this.currentAlert.startAlert.hour ? common.setHoursMinute(this.currentAlert.startAlert.hour, this.currentAlert.startAlert.date) : this.currentAlert.startAlert.date)
        let stop = moment(this.currentAlert.endOfAlert.hour ? common.setHoursMinute(this.currentAlert.endOfAlert.hour, this.currentAlert.endOfAlert.date) : this.currentAlert.endOfAlert.date)

        this.intervalDateError = start.isAfter(stop)
      } else {
        this.intervalDateError = false
      }
    }
  },
  validators: {
    'currentAlert.name': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.description': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.startAlert.date': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.startAlert.hour': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.endOfAlert.date': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.endOfAlert.hour': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.initiator': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.type': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.solution': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.isNewPickupLocation': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.newPickupLocation': function (value) {
      if (this.currentAlert.isNewPickupLocation) {
        return Validator.value(value).required()
      }
    },
    'currentAlert.isReplacementBus': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.alternedJourneyTime': function (value) {
      if (this.currentAlert.isReplacementBus) {
        return Validator.value(value).required()
      }
    },
    'currentAlert.contactName': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.contactPhone': function (value) {
      return Validator.value(value).required()
    },
    'currentAlert.contactEmail': function (value) {
      return Validator.value(value).required().email()
    }
  },
  components: {
    Datepicker,
    Timepicker,
    InputRemainingCharacters
  }
}
</script>
